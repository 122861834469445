export enum Roles  {
    ADMIN = 'admin'
}

export enum StoneState {
    PENDING = 'pending',
    PUBLIC = 'public',
    REJECT = 'reject',
    PRIVATE = 'private',
    REVIEW = 'review',
}

export enum StoneViewState {
    ALL = 'all',
    MY_STONES = 'myStones',
    PRIVATE = 'private',
    REJECTED = 'rejected',
    USERS_STONES = 'usersStones'
}

export enum StoneOwner {
    YOU = 'you',
    UNKNOWN = 'unknown'
}

export enum ReportType {
    ERROR = 'error',
    NEWS = 'news'
}

export enum Limits {
    NOTE_MAX_LENGTH = 300
}

export default function Footer() {
    return (
        <div className="container">
            <br />
            <br />
            <div className="row" id="loginRegister">
                <div className="col-md-6 offset-md-3 col-lg-12 offset-lg-0">

                    <h4>
                        Podmínky použití (všeobecné podmínky)
                    </h4>


                    <br />
                    <div>
                        <h6>Úvod</h6>
                        <ul>
                            <li>Vítejte ve světě putovních kamínků! Naše platforma vám umožňuje sdílet a objevovat putovní kamínky po celém světě. Tím, že využíváte naše služby, souhlasíte s těmito podmínkami použití.</li>
                        </ul>
                    </div>
                    <div>
                        <h6>Povolené a zakázané použití</h6>
                        <ul>
                            <li>Uživatelé se zavazují používat platformu pouze pro legální účely a v souladu s pravidly stanovenými zde. Platforma je určena pro sdílení zážitků z hledání a objevování putovních kamínků. Jakékoli jiné využití je považováno za zneužití a může vést k případným sankcím nebo k odstranění účtu.</li>
                        </ul>
                    </div>
                    <div>
                        <h6>Zakázaný obsah</h6>
                        <p>Je přísně zakázáno nahrávat nebo šířit jakékoli příspěvky, obrázky nebo komentáře, které obsahují:</p>
                        <ul>
                            <li><b>Nevhodný nebo vulgární obsah</b>: Všechny formy obsahu, které jsou považovány za nevhodné, vulgární nebo explicitní, jsou zakázány.</li>
                            <li><b>Nahotu nebo urážlivý obsah</b>: Příspěvky obsahující nahotu, pornografii nebo jiný urážlivý materiál jsou přísně zakázány a budou okamžitě odstraněny.</li>
                            <li><b>Hanlivé a rasistické komentáře</b>: Jakákoli forma diskriminace, rasismu, xenofobie nebo jiného hanlivého chování je nepřijatelná a povede k okamžitému odstranění příspěvků a možnému zákazu účtu.</li>
                        </ul>
                    </div>
                    <div>
                        <h6>Dodržování pravidel</h6>
                        <ul>
                            <li>Každý uživatel je zodpovědný za obsah, který na platformu nahrává. Při objevení jakéhokoli porušení těchto pravidel si platforma vyhrazuje právo odstranit příslušný obsah a podniknout další kroky, včetně odstranění účtu uživatele.</li>
                        </ul>
                    </div>
                    <div>
                        <h6>Podpora a hlášení</h6>
                        <ul>
                            <li>V případě, že narazíte na obsah, který považujete za nevhodný nebo porušující tato pravidla, vás žádáme, abyste jej nahlásili našemu týmu prostřednictvím funkce hlášení nebo na kontaktní email. Vážíme si vaší pomoci při udržování kvalitního a bezpečného prostředí na naší platformě.</li>
                        </ul>
                    </div>
                    <div>
                        <h6>Obsah uživatele</h6>
                        <ul>
                            <li>Při nahrávání obsahu, včetně fotografií a informací o kamíncích, uživatelé udělují platformě nevýhradní licenci k zobrazování, distribuci a propagaci tohoto obsahu v rámci služby. Uživatelé si ponechávají vlastnictví svých autorských práv.</li>
                        </ul>
                    </div>
                    <h5>Zásady ochrany osobních údajů</h5>
                    <br />
                    <div>
                        <h6>Sběr a použití informací</h6>
                        <ul>
                            <li>Informace o nalezených kamíncích, včetně fotografií a psč, jsou shromažďovány pro účely sdílení s komunitou a inspirace ostatních. Zpracováváme také uživatelská jména, hesla a kontaktní informace pro registraci a správu účtů.</li>
                        </ul>

                        <h6>Bezpečnost a sdílení údajů</h6>
                        <ul>
                            <li>Zabezpečení vašich osobních údajů je pro nás prioritou. Přijímáme vhodná opatření k ochraně vašich dat před neoprávněným přístupem a zneužitím. K tomuto účelu využíváme moderní a bezpečné služby od Amazon Web Services (AWS), které jsou lídrem v oblasti cloudových bezpečnostních technologií. Tato infrastruktura nám umožňuje implementovat pokročilé bezpečnostní protokoly a šifrování dat, aby byla zajištěna co nejvyšší úroveň ochrany vašich informací. Osobní údaje nebudou sdíleny s třetími stranami bez vašeho výslovného souhlasu, s výjimkou zákonných požadavků, kdy může být sdílení dat vyžadováno zákony nebo na základě soudního rozhodnutí. Ve všech takových případech postupujeme s maximální opatrností a transparentností, abychom zajistili ochranu vašich práv a soukromí.</li>
                        </ul>

                        <h6>Práva uživatele</h6>
                        <ul>
                            <li>Máte právo na přístup k vašim osobním údajům, jejich opravu, výmaz a omezení zpracování.</li>
                        </ul>
                    </div>

                    <h5>Zásady používání cookies</h5>
                    <br />
                    <div>
                        <h6>Použití cookies</h6>
                        <ul>
                            <li>Naše platforma používá cookies k zlepšení uživatelského zážitku, analýze návštěvnosti a personalizaci obsahu. Používáním naší služby souhlasíte s použitím cookies v souladu s těmito zásadami.</li>
                        </ul>

                        <h6>Správa cookies</h6>
                        <ul>
                            <li>Máte možnost spravovat a odmítat použití cookies prostřednictvím nastavení vašeho prohlížeče.</li>
                        </ul>
                    </div>

                    <h5>Autorská práva a vlastnictví obsahu</h5>
                    <br />
                    <div>
                        <h6>Vlastnictví a licence</h6>
                        <ul>
                            <li>Veškerý obsah na platformě, s výjimkou obsahu uživatelů, je vlastnictvím kaminkovnik.cz nebo jeho licencovaných partnerů. Uživatelský obsah zůstává pod vlastnictvím uživatele, který platformě uděluje licenci k jeho použití.</li>
                        </ul>
                    </div>

                    <h5>Změny a aktualizace</h5>
                    <br />
                    <div>
                        <h6>Aktualizace podmínek</h6>
                        <ul>
                            <li>Podmínky použití a další právní dokumenty mohou být kdykoliv aktualizovány. O všech významných změnách budete informováni prostřednictvím platformy nebo e-mailem.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}
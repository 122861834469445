import "./MessageManager.scss";

import React, { useReducer, useContext } from 'react';

import { reducer, initialState } from '../reducers/messageManager-reducer';
import * as actions from '../actions/messageManager-actions';

import { 
    hideMessageDialog,
    hideConfirmDialog
} from '@root/actions/messageManager-actions';
import {
    ConfirmTemplateActions
} from '@root/templates/confirmTemplates';

import CS from '@root/i18n/cs';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface Storage {
    stateMessages: any;
    dispatchMessages: any;
    actions: any;
}

const Messages = React.createContext({} as Storage);

export const useMessageManager = () => useContext(Messages);

interface ActionButtonProps {
    actions: any;
    dispatchMessages: React.Dispatch<any>;
}

function ActionButtons({ ...rest }: ActionButtonProps) {
    const { actions, dispatchMessages } = rest;

    const buttons = actions.map((action: ConfirmTemplateActions, index: number) => {
        switch(action) {
            case ConfirmTemplateActions.OK:
                return (
                    <Button key={index} variant="success" onClick={() => dispatchMessages(hideConfirmDialog(false))}>
                        {CS.labels.ok}
                    </Button>
                )
            case ConfirmTemplateActions.SAVE:
                return (
                    <Button key={index} variant="success" onClick={() => dispatchMessages(hideConfirmDialog(true))}>
                        {CS.labels.save}
                    </Button>
                )
            case ConfirmTemplateActions.CANCEL:
                return (
                    <Button key={index} variant="primary" onClick={() => dispatchMessages(hideConfirmDialog(false))}>
                        {CS.labels.cancel}
                    </Button>
                )
            case ConfirmTemplateActions.EDIT:
                return (
                    <Button key={index} variant="primary" onClick={() => dispatchMessages(hideConfirmDialog(false))}>
                        {CS.labels.edit}
                    </Button>
                )
            case ConfirmTemplateActions.REMOVE:
                return (
                    <Button key={index} variant="danger" onClick={() => dispatchMessages(hideConfirmDialog(true))}>
                        {CS.labels.remove}
                    </Button>
                )
        }
    });

    return buttons;
}

export function MessageManager ({ children }: any) {
    const [stateMessages, dispatchMessages] = useReducer(reducer, initialState);

    const value = {
        stateMessages, dispatchMessages, actions
    };
    const title = stateMessages?.message?.type ? 
        (CS.labels as any)[stateMessages?.message?.type] : CS.labels.edit

    return (
        <Messages.Provider value={value}>
            <div className="message-manager">
                {stateMessages?.busy &&
                    <div className="busy-indicator-wrapper">
                        <div className="busy-indicator">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>
                }

                {stateMessages?.message &&
                    <Modal show={stateMessages?.message !== undefined} onHide={() => {
                        dispatchMessages(hideMessageDialog());
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {stateMessages?.message?.message}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                dispatchMessages(hideMessageDialog());
                            }}>
                                ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }

                {stateMessages?.confirmDialog && stateMessages?.confirmDialog.visible &&
                    <Modal show={true} onHide={() => dispatchMessages(hideConfirmDialog(false))}>
                        <Modal.Header closeButton>
                            <Modal.Title>{stateMessages.confirmDialog.template.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {stateMessages.confirmDialog.template.content}
                        </Modal.Body>
                        <Modal.Footer>

                            <ActionButtons 
                                actions={stateMessages.confirmDialog.template.actions}
                                dispatchMessages={dispatchMessages}
                            />
                            
                            {/* <Button variant="secondary"onClick={() => {
                                dispatch(hideConfirmDialog(false));
                            }}>
                                {CS.labels.cancel}
                            </Button>
                            <Button variant="primary"onClick={() => {
                                dispatch(hideConfirmDialog(true));
                            }}>
                                {CS.labels.reject}
                            </Button> */}
                        </Modal.Footer>
                    </Modal>
                }

                {children}
            </div>
        </Messages.Provider>
    );
};
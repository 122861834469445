import * as types from './action-types';
import {
    ConfirmTemplate
} from '@root/templates/confirmTemplates';


export interface Message {
    type: string;
    message: string;
}

export enum TrackingTemplate {
    REMOVE_STONE
}

export const showMessage = (message: Message | undefined) => {
    return {
        type: types.SHOW_MESSAGE,
        value: message,
    };
};

export const hideMessageDialog = () => {
    return {
        type: types.HIDE_MESSAGE,
    };
};

export const showConfirmDialog = (id: number, template: ConfirmTemplate, meta?: any) => {
    return {
        type: types.SHOW_CONFIRM_DIALOG,
        id, template, meta
    };
};

export const hideConfirmDialog = (confirmed: boolean) => {
    return {
        type: types.HIDE_CONFIRM_DIALOG,
        value: confirmed
    };
};

export const setBusy = (busy: boolean) => {
    return {
        type: types.SET_BUSY,
        value: busy
    };
};
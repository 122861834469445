import "./LandingPage.scss";

import { useEffect, useState } from 'react';
import LoginRegister from './LoginRegister';
import ForgotPassword from './ForgotPassword';

export enum LoginMode {
    LOGIN,
    FIRST_LOGIN,
    REGISTER,
    FORGOT_PASSWORD
};

export default function LandingPage() {
    const [error, setError] = useState('');
    const [loginMode, setLoginMode] = useState(LoginMode.LOGIN);

    useEffect(() => {
        setTimeout(() => {
            if (sessionStorage.loginMessage) {
                setError(sessionStorage.loginMessage);
            }
            delete sessionStorage.loginMessage;
        }, 300);
    }, [])


    useEffect(() => {
        document.querySelector('#loginRegister')?.scrollIntoView({ behavior: "smooth" });
        setError('');
    }, [loginMode]);

    return (
        <div className="container landingPage-page" id="landingPage">
            <div className="row" id="loginRegister">
                <div className="col-md-6 offset-md-3 col-lg-6 offset-lg-3">
                    {(loginMode === LoginMode.FIRST_LOGIN || loginMode === LoginMode.LOGIN || loginMode === LoginMode.REGISTER) && 
                        <LoginRegister 
                            error={error}
                            setError={setError}
                            loginMode={loginMode}
                            changeMode={(mode: LoginMode) => setLoginMode(mode) }/>
                    }

                    {loginMode === LoginMode.FORGOT_PASSWORD && 
                        <ForgotPassword 
                            error={error}
                            setError={setError}
                            changeMode={(mode: LoginMode) => setLoginMode(mode) }/>
                    }
                </div>
            </div>
        </div>
    );
}

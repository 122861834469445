import "./MessagePanel.scss";
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { RiMoreFill } from 'react-icons/ri';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import CS from '@root/i18n/cs';

import { FaTrash } from 'react-icons/fa';

import { useAuth } from '../contexts/AuthContext';
import { visitMessagePanel, hideMessage } from "@root/service/stoneService";
import { useMessageManager } from '@root/contexts/MessageManager';


enum FilterMode {
    ALL = 'all',
    COMMENT = 'comment',
    RATING = 'rating',
    REPORT_ERROR = 'reportError',
    REPORT_NEWS  = 'reportNews'
}

enum MessageType {
    COMMENT = 'comment',
    REJECTED_STONE = 'rejectedStone',
    APPROVED_STONE = 'approvedStone',
    RATING = 'rating',
    REPORT_ERROR = 'reportError',
    REPORT_NEWS  = 'reportNews'
}

interface Message {
    trackingIdType: string;
    message?: string;
    type: string;
    trackingId: string;
    trackingDate: string;
    position: string;
    createdText: string;
    user: any;
}

interface MessagePanelProps {
    setShowMessagePanel: Dispatch<SetStateAction<boolean>>;
    messageCount: number;
}

export default function MessagePanel({ ...rest}: MessagePanelProps ) {
    const { setShowMessagePanel, messageCount } = rest;
    const { currentUser, refreshUser} = useAuth();
    const { dispatchMessages, actions } = useMessageManager();
    const navigate = useNavigate();

    const [messages, setMessages] = useState<Message[]>();
    const [filterMode, setFilterMode] = useState(FilterMode.ALL);

    // open for first time, call BE to disable notification
    useEffect(() => {
        if (messageCount > 0) {
            (async () => {
                await visitMessagePanel();
                await refreshUser();
            })();
        }
    }, []);


    useEffect(() => {
        if (filterMode === FilterMode.ALL) {
            setMessages(currentUser.info.user.messages);
        } else if (filterMode === FilterMode.COMMENT) {
            setMessages(currentUser.info.user.messages.filter((message: Message) => message.type === MessageType.COMMENT));
        } else if (filterMode === FilterMode.RATING) {
            setMessages(currentUser.info.user.messages.filter((message: Message) => message.type === MessageType.RATING));
        } else if (filterMode === FilterMode.REPORT_ERROR) {
            setMessages(currentUser.info.user.messages.filter((message: Message) => message.type === MessageType.REPORT_NEWS));
        } else if (filterMode === FilterMode.REPORT_NEWS) {
            setMessages(currentUser.info.user.messages.filter((message: Message) => message.type === MessageType.REPORT_NEWS));
        }
    }, [filterMode, currentUser]);

    async function handleMessageClick(e: React.SyntheticEvent, item: Message) {
        setShowMessagePanel(false);
        e.preventDefault();

        if (item.type === MessageType.COMMENT || item.type === MessageType.RATING) {
            navigate(`/stone/${item.trackingId}`);
        }
    }

    async function handleRemoveMessage(e: React.SyntheticEvent, item: Message) {
        e.preventDefault();
        try {
            dispatchMessages(actions.setBusy(true));
            await hideMessage(item.trackingIdType);
            await refreshUser();
            dispatchMessages(actions.setBusy(false));
            setShowMessagePanel(false);
        } catch(error: any) {
            dispatchMessages(actions.setBusy(false));
            // dispatch(showMessage({
            //     type: 'error',
            //     message: error.message
            // }));
        }
    }

    let showCommentFilter = false;
    let showRatingFilter = false;
    let showReportFilter = false;
    let showReportNewsFilter = false;

    
    let filterCount = 0 ;
    currentUser.info.user.messages?.forEach((msg: Message) => {
        switch(msg.type) {
            case FilterMode.COMMENT:
                showCommentFilter = true;
                filterCount++;
                break;
            case FilterMode.RATING:
                showRatingFilter = true;
                filterCount++;
                break;
            case FilterMode.REPORT_ERROR:
                showReportFilter = true;
                filterCount++;
                break;
            case FilterMode.REPORT_NEWS:
                showReportNewsFilter = true;
                filterCount++;
                break;
        }
    });

    let showAllFilter = filterCount > 1;


    return (
        <div className="message-panel-wrapper">
            <div className="message-panel">
                <div className="message-panel-main">
                    <div className="message-panel-main-wrapper">
                        <div className="message-panel-main-wrapper-fixed">
                            <AiOutlineClose size={30} onClick={() => setShowMessagePanel(false) } className="message-panel-close"></AiOutlineClose>

                            <h4 className="message-panel-title">{CS.components.messagePanel.title}</h4>

                            {showAllFilter &&
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" 
                                        onClick={() => setFilterMode(FilterMode.ALL)}
                                        className={"btn " + (filterMode === FilterMode.ALL ? "btn-primary" : "btn-secondary")}>{CS.components.messagePanel.all}</button>
                                    {showCommentFilter &&
                                        <button type="button" 
                                            onClick={() => setFilterMode(FilterMode.COMMENT)}
                                            className={"btn " + (filterMode === FilterMode.COMMENT ? "btn-primary" : "btn-secondary")}>{CS.components.messagePanel.comments}</button>
                                    }
                                    {showRatingFilter &&
                                        <button type="button" 
                                            onClick={() => setFilterMode(FilterMode.RATING)}
                                            className={"btn " + (filterMode === FilterMode.RATING ? "btn-primary" : "btn-secondary")}>{CS.components.messagePanel.rating.title}</button>
                                    }
                                    {showReportFilter &&
                                        <button type="button" 
                                            onClick={() => setFilterMode(FilterMode.REPORT_ERROR)}
                                            className={"btn " + (filterMode === FilterMode.REPORT_ERROR ? "btn-primary" : "btn-secondary")}>{CS.components.messagePanel.reportErrror.title}</button>
                                    }
                                    {showReportNewsFilter &&
                                        <button type="button" 
                                            onClick={() => setFilterMode(FilterMode.REPORT_NEWS)}
                                            className={"btn " + (filterMode === FilterMode.REPORT_NEWS ? "btn-primary" : "btn-secondary")}>{CS.components.messagePanel.reportNews.title}</button>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    <div className="message-panel-content list-group">
                        {messages && messages.map((item: Message, index: number) =>
                            <div key={index} onClick={(e) => handleMessageClick(e, item) } className={"message-panel-content-item list-group-item list-group-item-action flex-column align-items-start " + item.type}>
                                <span className="message-panel-content-item-avatar">
                                    {item.user?.photoURL && item.type !== MessageType.REPORT_NEWS &&
                                        <img src={item.user?.photoURL} alt="Avatar"></img>
                                    }
                                </span>
                                <div className="message-panel-content-item-type d-flex w-100 justify-content-between">
                                    {item.type === MessageType.COMMENT &&
                                        <h5 className="mb-10 message-panel-content-item-title">{CS.components.messagePanel.newComment}</h5>
                                    } 
                                    {item.type === MessageType.RATING &&
                                        <h5 className="mb-10 message-panel-content-item-title">{CS.components.messagePanel.newRating}</h5>
                                    } 
                                    {item.type === MessageType.REPORT_ERROR &&
                                        <h5 className="mb-10 message-panel-content-item-title">{CS.components.messagePanel.newReportError}</h5>
                                    } 
                                    {item.type === MessageType.REJECTED_STONE &&
                                        <h5 className="mb-10 message-panel-content-item-title">{CS.components.messagePanel.rejectedStone}</h5>
                                    } 
                                    {item.type === MessageType.APPROVED_STONE &&
                                        <h5 className="mb-10 message-panel-content-item-title">{CS.components.messagePanel.approveStone.title}</h5>
                                    } 
                                    {item.type === MessageType.REPORT_NEWS &&
                                        <h5 className="mb-10 message-panel-content-item-title">{CS.components.messagePanel.newReportNews}</h5>
                                    } 
                                    <small className="message-panel-content-item-time">{item.createdText}</small>
                                </div>

                                {item.type === MessageType.COMMENT &&
                                    <p className="message-panel-content-item-info">{CS.components.messagePanel.user} <b>{item.user?.userName}</b> {CS.components.messagePanel.user} {CS.components.messagePanel.comment.part1} {item.trackingDate} {CS.components.messagePanel.comment.part2} {item.position}.</p>
                                }
                                {item.type === MessageType.APPROVED_STONE &&
                                    <p className="message-panel-content-item-info">{CS.components.messagePanel.user} <b>{item.user?.userName}</b> {CS.components.messagePanel.user} {CS.components.messagePanel.approveStone.part1} {item.trackingDate} {CS.components.messagePanel.approveStone.part2} {item.position}.</p>
                                }
                                {item.type === MessageType.RATING &&
                                    <p className="message-panel-content-item-info">{CS.components.messagePanel.user} <b>{item.user?.userName}</b> {CS.components.messagePanel.rating.part1} {item.trackingDate} {CS.components.messagePanel.rating.part2} {item.position}.</p>
                                }
                                {(item.type === MessageType.REPORT_ERROR || item.type === MessageType.REPORT_NEWS ||
                                    item.type === MessageType.REJECTED_STONE
                                ) &&
                                    <p className="message-panel-content-item-info">
                                        {CS.components.messagePanel.user} <b>{item.user?.userName}</b> {CS.components.messagePanel.rejectStone.title} {item.trackingDate} {CS.components.messagePanel.comment.part2} {item.position}.
                                        {CS.components.messagePanel.rejectStone.reason}: <b>{item.message}</b>
                                    </p>
                                }
                                {/* <small className="float-end message-panel-content-item-actions" title={CS.components.messagePanel.options}>
                                    <RiMoreFill onClick={(e: any) => handleRemoveMessage(e, item) }></RiMoreFill>
                                </small> */}

                                <div className="float-end message-panel-content-item-actions" onClick={(e) => e.stopPropagation() }>
                                    <RiMoreFill type="button" data-bs-toggle="dropdown" aria-expanded="false"></RiMoreFill>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item" href="#" onClick={(e: any) => handleRemoveMessage(e, item)}>
                                                <FaTrash title="odstranit komentar" /> Smazat
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}

                        {/* <a href="#" className="message-panel-content-item list-group-item list-group-item-action flex-column align-items-start">
                            <span className="message-panel-content-item-avatar">
                                <img src="./images/user.png" alt="Avatar"></img>
                            </span>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-10 message-panel-content-item-title">Nový hodnoceni</h5>
                                <small className="message-panel-content-item-time">před 12 hodinami</small>
                            </div>

                            <p className="message-panel-content-item-info">Uživalel <b>fero</b> hodnotil váš kamínek vytvořený dne 19.2.1989 nalezený v místě Praha.</p>
                            <small className="float-end message-panel-content-item-actions" title="možnosti">
                                <RiMoreFill ></RiMoreFill>
                            </small>
                        </a> */}

                    </div>
                </div>
            </div>
        </div>
    )
}
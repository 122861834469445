import "./StoneDetail.scss";
import { useState, useEffect } from 'react';
import CS from '@root/i18n/cs';

import { useMessageManager } from '@root/contexts/MessageManager';
import { showMessage, showConfirmDialog } from '@root/actions/messageManager-actions';
import { ConfirmTemplates, ConfirmTemplateActions } from '@root/templates/confirmTemplates';
import { MapHolder, StoneGallery } from '@root/components';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';


import { useAuth } from '../../contexts/AuthContext';
import { StoneState, Roles } from '@root/helpers/constants';
import { 
    getStone, 
    updateStonePointer, 
    deleteStone,     
    ZipItem
} from "@root/service/stoneService";
import { getPhotos } from '@root/helpers/stoneHelper';

import { MdGpsFixed } from 'react-icons/md';
import { AiOutlineLike, AiFillLock } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';

import { Comments } from '@root/components';
import { mapCommentCount } from '@root/helpers/shared';


import { useParams } from "react-router-dom";

export default function StoneDetail() {
    const [busy, setBusy] = useState(false);
    const [stone, setStone] = useState<any>();
    const [photos, setPhotos] = useState();
    const [stonePointers, setStonePointers] = useState<any>([]);
    const [likes, setLikes] = useState<any>([]);
    let { id } = useParams<any>();

    const { currentUser, refreshUser } = useAuth();
    const { stateMessages, dispatchMessages } = useMessageManager();

    const [confirmDialogId, setConfirmDialogId] = useState<any>(0);
    const [confirmDialogDeleteStoneId, setConfirmDialogDeleteStoneId] = useState<any>(0);
    
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState<any>();


    useEffect(() => window.scroll({ top: 0, behavior: 'smooth' }) , []);
    
    useEffect(() => {
        if (id) {
            setBusy(true);
            getStone(id).then((stone: any) => {
                setLikes(stone.likes);
                updateStoneDetail(stone)
                setBusy(false);
            }).catch(error => {
                setBusy(false);
                alert(error.message);
                // dispatch(showMessage({
                //     type: 'error',
                //     message: error.message
                // }));
            });
        }
    }, [id]);

    function updateStoneDetail(stone: any) {
        setStone(stone);
        setPhotos(getPhotos(stone.stones));

        const pointers: any = []
        stone.stones
            .filter((item: any) => item.pointers)
            .forEach((item: any) => {
                item.pointers.forEach((pointer: any) => {
                    pointers.push({
                        ...pointer,
                        stoneId: item.stoneId,
                    });
                })
            });
        setStonePointers(pointers);
    }


    useEffect(() => {
        if (message2) {
            dispatchMessages(message2);
        }
    }, [ message2]);

    function myStoneTemplate(pointer: any) {
        const actions: any = pointer.user ?
            [ConfirmTemplateActions.CANCEL, message.length > 0 ? ConfirmTemplateActions.SAVE : null] :
            [ConfirmTemplateActions.CANCEL, message.length > 0 ? ConfirmTemplateActions.SAVE : null];

        return showConfirmDialog(id as any, { 
            title: <>{pointer.user ? CS.templates.chooseMyStone.edit.title : CS.templates.chooseMyStone.title}</>,
            content: (
                <>
                    Pokud se jedná o Váš kamínek, který jste namaloval/la tak ho prosím pojmenujte a stiskněte tlačítko 'uložit'.
                    <textarea 
                        className="form-control"
                        value={message}
                        required={true}
                        onChange={(e: any) => {
                            setMessage(e.target.value);
                        }}
                        maxLength={1000}
                        rows={1}>
                    </textarea>
                </>
            ),
            actions: actions.filter((item: any) => item)
        })
    }
    
    useEffect(() => {
        if (message2) {
            dispatchMessages(myStoneTemplate(confirmDialogId));
        }
    }, [message]);

    // confirm dialog to update stone count
    useEffect(() => {
        (async () => {
            if (stateMessages.confirmDialog && stateMessages.confirmDialog.confirmed) {
                if (stateMessages.confirmDialog.id === id && message && id) {
                // if (stateMessages.confirmDialog.id === confirmDialogId && message && id) {
                    const { stoneId, pointerId } = confirmDialogId;
                    try {
                        setBusy(true);
                        const stone = await updateStonePointer({ trackingId: id, stoneId, pointerId, message });
                        updateStoneDetail(stone);
                        await refreshUser();
                        setBusy(false);
                    } catch(e) {
                        setBusy(false);
                    }
                }
            }
        })();

    }, [stateMessages])

    // confirm dialog to delete stone
    // delete stone
    useEffect(() => {
        (async () => {
            if (stateMessages.confirmDialog && stateMessages.confirmDialog.confirmed) {
                if (stateMessages.confirmDialog.id === confirmDialogDeleteStoneId) {
                    setBusy(true);
                    try {
                        await deleteStone(stone.trackingId);
                        history.back();
                    } catch(error: any) {
                        setBusy(false);
                        dispatchMessages(showMessage({
                            type: 'error',
                            message: error.message
                        }));
                    }
                }
            }
        })();
    }, [stateMessages])

    function myStoneHandler(pointer: any) {
        // debugger;
        // const id = +new Date();
        setConfirmDialogId(pointer);
        setMessage2(myStoneTemplate(pointer));
    }

    // async function editStoneHandler(strone: Stone) {
    //     navigate('/editstone/' + strone.trackingId);
    // }

    async function deleteStoneHandler() {
        let template = isAdmin && !isUserStoneOwner ? ConfirmTemplates.ADMIN_REMOVE_STONE : ConfirmTemplates.REMOVE_STONE;

        const id = +new Date();
        setConfirmDialogDeleteStoneId(id);

        dispatchMessages(showConfirmDialog(id, template));
    }

    // function shareToFacebook() {
    //     const groupId = '1401790953911556';
    //     const accessToken = '257702769900328';
    //     const pageUrl = 'https://test.kaminkovnik.cz/#/stone/1d326d73-2af8-4639-b69d-5cf01ebf8345';
    //     const message = 'Check out this page!';

    //     const endpoint = `https://graph.facebook.com/${groupId}/feed`;
    //     const params = new URLSearchParams({
    //         access_token: accessToken,
    //         link: pageUrl,
    //         message: message,
    //     });

    //     fetch(endpoint, {
    //         method: 'POST',
    //         body: params,
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log('Page shared successfully:', data);
    //     })
    //     .catch(error => {
    //         console.error('Error sharing page:', error);
    //     });

    // }

    const isAdmin = currentUser.info.user.role === Roles.ADMIN;
    const isUserStoneOwner = stone && stone.uid === currentUser.info.user.userId;

    return (
        <div className="container stone-detail" id="stoneDetail">
            
            {busy &&
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }
            <br />

            {!busy && stone &&
                <>
                    <div className="stone-detail-header">
                        <h4 className="stone-detail-header-title">{CS.pages.stoneDetail.title}</h4>
                        <div className="card stone-detail-header-content">
                            <div className="card-body">
                                <div className={"row stone-detail-header-content-body" + (!stone.positionData ? ' missing-gps' : '')}>
                                    <div className="col-md-8">
                                        {stone.user?.photoURL &&
                                            <span className="avatar">
                                                <img src={stone.user.photoURL} alt="Avatar"></img>
                                            </span>
                                        }

                                        {(!stone.user || !stone.user.photoURL) &&
                                            <span className="avatar">
                                                <img src="./images/user.png" alt="Avatar"></img>
                                            </span>
                                        }
                                        <h5 className="card-title">{stone.user?.userName}</h5>
                                        <div className="created-date">{stone.date}</div>
                                        {stone.state === StoneState.PRIVATE && 
                                            <><AiFillLock color="red" />&nbsp;</>
                                        }

                                        {stone.positionData &&
                                            <span>
                                                <MdGpsFixed color="blue" />
                                                &nbsp;{stone.positionData.label}
                                            </span>
                                        }

                                    </div>
                                    <div className="col-md-4">
                                        {stone.positionData &&
                                            <>
                                                <MapHolder 
                                                    zoomControl={false}
                                                    dragging={false}
                                                    scrollWheelZoom={false}
                                                    initialPosition={{ lat: stone.positionLat, lng: stone.positionLng }}
                                                />

                                            </>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {photos && 
                        <StoneGallery
                            photos={photos}
                            stonePointersHook={[stonePointers, setStonePointers]}
                            myStoneHandler={myStoneHandler}
                        ></StoneGallery>
                    }

                    <div className="stone-detail-info">
                                    
                        <div className="stone-detail-info-like-count">
                            {(likes.length > 0) &&
                                <OverlayTrigger
                                    overlay={<Tooltip className="likes-tooltip">
                                        {likes.map((like: any) => {
                                            return (
                                                <>
                                                    {like.userName}<br/>
                                                </>
                                            );
                                        })}
                                    </Tooltip>}
                                    >
                                        <Button variant="link">
                                            <AiOutlineLike  /> {likes.length}
                                        </Button>
                                </OverlayTrigger>
                            }
                        </div>

                        <div className="stone-detail-info-comments-count">
                            {stone.comments.length > 0 &&
                                <>
                                    {stone.comments.length} {mapCommentCount(stone.comments.length)}
                                </>
                            } 
                        </div>
                    </div>

                    {stonePointers.length > 0 &&
                        <>
                            <div className="alert alert-info" role="info">
                                {CS.pages.stoneDetail.info.aboutPointers}
                            </div>
                            <hr />

                            <div className="card">
                                <div className="card-body">
                                    <h4>{CS.pages.stoneDetail.info.title}</h4>
                                    
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between align-items-start">
                                            {CS.pages.stoneDetail.info.stoneCount} {stonePointers.length}
                                            <span>{stone.stoneCount}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    }

                    <div className="trackItem-header-zipList">
                        {stone.zipRawList.map((item: ZipItem, index: any) => {
                            return (
                                <span key={index} className="badge rounded-pill bg-primary">
                                    <span className="badge-label">{`${item.zip} - ${item.district}`}</span>
                                </span>
                            );
                        })}
                    </div>


                    {stone.description &&
                        <div className="card">
                            <div className="card-body">
                                <span>{stone.description}</span>
                            </div>
                        </div>
                    }

                    <h5 className="stone-detail-section">{CS.pages.stoneDetail.userActions}</h5>

                    {(isUserStoneOwner || isAdmin) &&
                        <div className="stone-detail-action-buttons row justify-content-md-center">
                            {/* {isUserStoneOwner &&
                                <div className="col stone-detail-action-buttons-item edit" onClick={() => editStoneHandler(stone) }>
                                    <AiOutlineEdit title={CS.components.stoneContent.edit} />
                                    <span>{CS.components.stoneContent.edit}</span>
                                </div>
                            } */}
                            <div className="col stone-detail-action-buttons-item delete" onClick={deleteStoneHandler}>
                                {isAdmin && !isUserStoneOwner &&
                                    <FaTrash color="orange" title={CS.components.stoneContent.deleteText} />
                                }
                                {(!isAdmin || isUserStoneOwner) &&
                                    <FaTrash color="red" title={CS.components.stoneContent.deleteText} />
                                }
                                <span>{CS.components.stoneContent.delete}</span>
                            </div>
                            <hr />
                        </div>
                    }

                    <h5 className="stone-detail-section">{CS.pages.stoneDetail.comments}</h5>

                    <Comments 
                        stone={stone} 
                    />


                    {/* <button onClick={shareToFacebook}>
                        share
                    </button> */}
                </>
            }

        </div>
    );
};

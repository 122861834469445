import { ReactElement } from 'react';
import CS from '@root/i18n/cs';

export interface ConfirmTemplate {
    title: ReactElement;
    content: ReactElement;
    actions: ConfirmTemplateActions[];
}

export enum ConfirmTemplateActions {
    OK,
    CANCEL,
    EDIT,
    REMOVE,
    SAVE
}

interface HashTable<T> {
    [key: string]: T;
}

export const ConfirmTemplates: HashTable<ConfirmTemplate> = {
    REMOVE_STONE: {
        title: <>{CS.templates.removeStone.title}</>,
        content: <>{CS.templates.removeStone.content}</>,
        actions: [ConfirmTemplateActions.CANCEL, ConfirmTemplateActions.REMOVE]
    },
    ADMIN_REMOVE_STONE: {
        title: <>{CS.templates.adminRemoveStone.title}</>,
        content: <>{CS.templates.adminRemoveStone.content}</>,
        actions: [ConfirmTemplateActions.CANCEL, ConfirmTemplateActions.REMOVE]
    },    
    REMOVE_COMMENT: {
        title: <>{CS.templates.removeComment.title}</>,
        content: <>{CS.templates.removeComment.content}</>,
        actions: [ConfirmTemplateActions.CANCEL, ConfirmTemplateActions.REMOVE]
    },
    ADMIN_REMOVE_COMMENT: {
        title: <>{CS.templates.adminRemoveComment.title}</>,
        content: <>{CS.templates.adminRemoveComment.content}</>,
        actions: [ConfirmTemplateActions.CANCEL, ConfirmTemplateActions.REMOVE]
    },
    CHOOSE_MY_STONE: {
        title: <>{CS.templates.chooseMyStone.title}</>,
        content: <></>,
        actions: [ConfirmTemplateActions.CANCEL, ConfirmTemplateActions.SAVE]
    },
    REMOVE_ACCOUNT: {
        title: <>{CS.templates.removeAccount.title}</>,
        content: <>{CS.templates.removeAccount.content}</>,
        actions: [ConfirmTemplateActions.CANCEL, ConfirmTemplateActions.REMOVE]
    },
    MISSING_GPS: {
        title: <>{CS.templates.missingGps.title}</>,
        content: <>{CS.templates.missingGps.content}</>,
        actions: [ConfirmTemplateActions.EDIT, ConfirmTemplateActions.SAVE]
    },
}


import "./EditStone.scss";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CS from '@root/i18n/cs';

import { useAuth } from '../../contexts/AuthContext';
import { getStone, ZipItem, ImageData, Stone } from "@root/service/stoneService";

import ImageItem from './ImageItem';
import { MapHolder } from '@root/components';
import { MapPosition } from '@root/helpers/definitions';
import { editStone } from '@root/helpers/errorHandling';
import { Limits } from '@root/helpers/constants';


import { useParams } from "react-router-dom";
import { StoneState } from '@root/helpers/constants';

export default function EditStone() {
    const [busy, setBusy] = useState(false);
    const [stoneCount, setStoneCount] = useState<any>(1);

    // const [stoneOwner, setStoneOwner] = useState<StoneOwner>();
    const [images, setImages] = useState<any[]>([]);
    const [position, setPosition] = useState<MapPosition>({} as MapPosition);
    const [positionData, setPositionData] = useState('');
    const [stone, setStone] = useState<any>();
    const [description, setDescription] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState<any>();
    const [selectedZipList, setSelectedZIPList] = useState<ZipItem[]>([]);
    let { id } = useParams();

    useEffect(() => window.scroll({ top: 0, behavior: 'smooth' }) , []);

    // TODO: vice kaminku
    useEffect(() => {
        setBusy(true);
        getStone("" + id).then((stone: Stone) => {
            // setInitialSelectedZIPList(stone.zipRawList);
            setSelectedZIPList(stone.zipRawList);

            setStone(stone)
            setStoneCount(stone.stoneCount);
            setDescription(stone.description);
            setImages(stone.stones);
            setBusy(false);
        })
    }, [id]);

    useEffect(() => {
        if (stone) {
            setStone({
                ...stone, 
                description, 
                stoneCount,
                zipRawList: selectedZipList
            });
        }
    }, [description, stoneCount, selectedZipList]);

    const { currentUser, refreshUser } = useAuth();
    const navigate = useNavigate();

    // const removeImage = (image: any) => {
    //     setImages((images: any) => {
    //         const data = [...images];
    //         data.splice(data.indexOf(image), 1);
    //         return data;
    //     });
    // }

    function handleFileClick(e: any) {
        setFileErrorMessage(null);


        const file = e.target.files![0];
        if (file && file['type'].split('/')[0] === 'image') {
            setImages((images: any) => [...images, new ImageData(file)]);
        } else {
            setFileErrorMessage(<>Nepovolený vstupní soubor. Můžete nahrát pouze soubory s příponou <b>jpg, jpeg, png	</b></>);
        }
    }

    async function handleSave(e: React.SyntheticEvent) {
        e.preventDefault();
        if (stone) {
            setBusy(true);
debugger;
            const isPublicStone = stone.state !== StoneState.PRIVATE;
            if (isPublicStone) {
                stone.state = StoneState.PENDING;
            }

            // await updateStone(stone, currentUser);
            await refreshUser();

            if (isPublicStone) {
                editStone(currentUser.displayName);
                navigate('/stones');
            } else {
                navigate('/stones?private');
            }
        }

        // await addNewStone({
        //     stoneOwner: stoneOwner,
        //     zipList: selectedZipList,
        //     images: images,
        //     description,
        //     userUid: currentUser.uid,
        //     stoneCount
        // }).then(() => {
        //     // document.location.reload();
        //     setBusy(false);

        //     if (stoneOwner === StoneOwner.UNKNOWN) {
        //         setStoneCreated(true);
        //         addNewSttone(currentUser.displayName);
        //     } else {
        //         navigate('/stones?private');
        //     }
        // });
        
        // refreshUser();
    }

    return (
        <div className="container" id="editStone">
            {/* {JSON.stringify(stone)} */}
            {busy &&
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }
            <br />

            {!busy &&
                <>
                    <>
                        <div className="alert alert-info" role="alert">
                            <h4 className="alert-heading">{CS.pages.editStone.header}</h4>
                            <p>{CS.pages.editStone.description}</p>
                        </div>

                        {stone &&
                            <MapHolder 
                                scrollWheelZoom={false}
                                position={position}
                                setPosition={setPosition}
                                positionData={positionData}
                                setPositionData={setPositionData}
                                initialPosition={{ lat: stone.positionLat, lng: stone.positionLng }}
                            />
                        }
                    
                        {/* <ZipSelector
                            title={CS.pages.addNewStone.owner.you.wizard.zipInfo}
                            initialSelectedZIPList={initialSelectedZIPList}
                            selectedZipList={selectedZipList}
                            changed={(items: ZipItem[]) => {
                                setSelectedZIPList(items);
                            }}
                        /> */}
                        <br />
                        <br />
                        {selectedZipList.length > 0 &&
                            <form onSubmit={handleSave}>
                                <div className="row justify-content-md-center">
                                    {images && images.map((item: any, index: number) => {
                                        return (
                                            <div className="col-sm-12 col-lg-6" key={index}>
                                                <ImageItem
                                                    // remove={removeImage}                                                
                                                    index={index}
                                                    item={item}
                                                    initialSelectedZIPList={item.zipList}
                                                    selectedZipList={selectedZipList}
                                                    zipChanged={(items: any) => {
                                                        setImages((images: any) => {
                                                            const data = [...images];
                                                            const updateItem = data[data.indexOf(item)];
                                                            updateItem.zipList = items;
                                                            return data;
                                                        })
                                                    }}
                                                ></ImageItem>
                                            </div>
                                        );
                                    })}
                                </div>

                                {images && images.length > 0 &&
                                    <>
                                        <br />
                                    </>
                                }

                                {images.length > 20 &&
                                    <div className="alert alert-primary" role="alert">{CS.pages.addNewStone.messages.warning.maxImages}</div>
                                }

                                {/* <button disabled={images.length > 20} className='btn btn-primary' type="button" onClick={() => {
                                    const item = document.querySelector('#file-upload');
                                    (item as any).click();
                                }}>
                                    <GoPlus /> {CS.pages.addNewStone.images.addImage} 
                                </button> */}

                                <input
                                    className='hidden-element'
                                    type="file"
                                    id="file-upload"
                                    onChange={handleFileClick}
                                />

                                {fileErrorMessage &&
                                    <>
                                        <br /><br />
                                        <div className="alert alert-danger" role="alert">
                                            {fileErrorMessage}
                                        </div>
                                    </>
                                }

                                <br />
                                <br />

                                <div className="card p-3">
                                    <div className="mb-3">
                                        <label htmlFor="note" className="form-label">
                                            {CS.pages.addNewStone.note.replace('%', (Limits.NOTE_MAX_LENGTH - description.length).toString())}
                                        </label>
                                        <textarea 
                                            maxLength={Limits.NOTE_MAX_LENGTH}
                                            value={description}
                                            onChange={e => setDescription(e.target.value!)}
                                            className="form-control" id="note" rows={3}></textarea>
                                    </div>

                                    <br />

                                    {stoneCount > 0 && stoneCount < selectedZipList.length &&
                                        <div className="alert alert-danger" role="alert">
                                            {CS.pages.addNewStone.messages.warning.ensureStoneCount
                                                .replace('{}', '' + selectedZipList.length)
                                                .replace('{}', stoneCount)
                                            }
                                        </div>
                                    }
                                    {/* {stoneCount < 1 && !images.length &&
                                        <div className="alert alert-warning" role="alert">
                                            Alespoň 1 kamínek máte, ne?
                                        </div>
                                    } */}
                                    {!busy &&
                                        <button 
                                            type="submit"
                                            className={'btn btn-success' + (!images.length ? ' dimmed' : '')} 
                                            disabled={!selectedZipList.length || !images.length || stoneCount < 1 || stoneCount < selectedZipList.length}>{CS.labels.save}</button>
                                    }

                                    <br />
                                    <br />
                                </div>
                            </form>
                        }
                    </>
                </>
            }

        </div>
    );
};

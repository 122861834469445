import { isTestEnv, isLocalEnv } from '@root/helpers/shared';
import { getJwtToken } from '@root/contexts/AuthContext';


export function sendMessage(userName: string, message: any) {
    var http = new XMLHttpRequest();
    var HOOK_URL = 'https://hooks.slack.com/services/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';
    var data = {"text": `*${userName}* ${JSON.stringify(message)}`};
    http.open("POST", HOOK_URL, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.send('payload=' + JSON.stringify(data));
}

export function addNewSttone(userName: string) {
    const isTest = isTestEnv();
    
    var http = new XMLHttpRequest();
    var HOOK_URL = 'https://hooks.slack.com/services/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';
    var data = {"text": `${isTest ? '[TEST] ' : ''}Uživatel *${userName}* přidal nový kamínek ke schválení.`};
    http.open("POST", HOOK_URL, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.send('payload=' + JSON.stringify(data));
}

export function editStone(userName: string) {
    const isTest = isTestEnv();
    
    var http = new XMLHttpRequest();
    var HOOK_URL = 'https://hooks.slack.com/services/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';
    var data = {"text": `${isTest ? '[TEST] ' : ''}Uživatel *${userName}* upravil kamínek ke schválení.`};
    http.open("POST", HOOK_URL, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.send('payload=' + JSON.stringify(data));
}

var errorTmp = console.error;
        
window.onerror = function (msg) {
    console.error(msg);
}

console.error = function (msg, error) {
    errorTmp.apply(this, arguments as any);

    if (!isLocalEnv()) {
        getJwtToken().then((resp: any) => {
            const { decodedJwt } = resp;
            let userName = 'unknown';
            if (decodedJwt) {
                userName = decodedJwt['cognito:username'];
            }
    
            try {
                msg = msg + ' |[' + error.toString()
            } catch(e) {
            }
            let stack = new Error().stack;
            msg += '|' + stack;
            sendMessage(userName, msg);
        })
    }
}
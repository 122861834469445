import * as types from '../actions/action-types';

export const initialState = {
    search: '',
    busy: false
};

export const reducer = (state = initialState, action: any) => {
    if (action.type === types.SEARCH) {
        return {
            ...state,
            search: action.value,
        };
    } 
    // throw "unknown action";
};
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Outlet } from 'react-router-dom';

export default function LoggedInRouter({ component: Component, ...rest }: any) {
	const { currentUser } = useAuth();
    const { reverse } = rest;

    const match = reverse ? !currentUser : currentUser;
    return (
        match  ? <Outlet /> : <Navigate to="/" />
    )
}
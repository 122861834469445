import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useAuth } from '@root/contexts/AuthContext';
import { useNavigate, NavLink } from 'react-router-dom';
import { isTestEnv, isLocalEnv } from '@root/helpers/shared';

import CS from '@root/i18n/cs';
import "./LandingPage.scss";

import { PasswordToggle, PasswordRules, Captcha } from '@root/components';
import { LoginMode } from './LandingPage';

interface ForgotPasswordProps {
    error: any;
    setError: Dispatch<SetStateAction<string>>,
    changeMode: (mode: LoginMode) => void;
}

const TIMEOUT_RESET = 300;

export default function ForgotPassword({ ...rest }: ForgotPasswordProps) {
    const { 
        error,
        setError,
        changeMode 
    } = rest;

    const { forgotPassword, confirmPassword } = useAuth();

    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isCaptchValid, setIsCaptchValid] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValidate, setPasswordValidate] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [passwordisValid, setPasswordisValid] = useState(false);
    const [busy, setBusy] = useState(false);

    const [confirmChangeMode, setConfirmChangeMode] = useState(false);
    const [timeoutInterval, setTimeoutInterval] = useState<any>();
    const [timeout, setTimeout] = useState(TIMEOUT_RESET);
    const navigate = useNavigate();

    useEffect(() => {
        if (timeout === 0) {
            setTimeout(TIMEOUT_RESET);
            setConfirmChangeMode(false);
        }
    }, [timeout]);

    useEffect(() => {
        if (confirmChangeMode) {
            clearInterval(timeoutInterval);

            setTimeoutInterval(setInterval(() => {
                setTimeout(t => t - 1);
            }, 1000));
        }
    }, [confirmChangeMode]);

    async function handleForgotPasswordSubmit(e: any) {
        e.preventDefault();

        try {
            setConfirmChangeMode(false);
            delete sessionStorage.loginMessage;
            setBusy(true);
            setError('');
            await forgotPassword(email.trim());
            setConfirmChangeMode(true);
        } catch (err: any) {
            sessionStorage.loginMessage = err.toString(); // CS.pages.forgotPassword.messages.error.validateEmail;
            setBusy(false);
            console.error(err);
            setError(sessionStorage.loginMessage);
        }

        return false;
    }
    
    async function handleCompleteForgotPasswordSubmit(e: any) {
        e.preventDefault();

        try {
            delete sessionStorage.loginMessage;
            setBusy(true);
            setError('');
            await confirmPassword(email.trim(), verificationCode.trim(), password.trim());
            navigate('/')
        } catch (err: any) {
            debugger;
            sessionStorage.loginMessage = err.toString();// CS.pages.forgotPassword.messages.error.unknownError;
            setBusy(false);
            console.error(err);
            setError(sessionStorage.loginMessage);
        }

        return false;
    }

    return (
        <div className="loginRegister-panel">
            <div className="loginRegister-panel-header">
                {!confirmChangeMode && CS.pages.forgotPassword.title}
                {confirmChangeMode && CS.pages.forgotPassword.titleFinish}
            </div>

            <div className="loginRegister-panel-body">
                {!confirmChangeMode &&
                    <>
                        <form onSubmit={handleForgotPasswordSubmit}>
                            <div className="col-md-12">
                                <label className="form-label">{CS.labels.email}</label>
                                <input name="email" className="form-control" autoCapitalize="none" type="text" value={email} onChange={e => setEmail(e.target.value!)} />
                            </div>

                            <div className="mb-3">
                                <Captcha captchValid={setIsCaptchValid}/>
                            </div>
                            
                            <div className="col-md-12">
                                <br />
                                <div className="d-grid gap-2">
                                    <button disabled={!busy && (!email || !isCaptchValid) } type="submit" className="btn btn-primary">
                                        {CS.labels.submit}
                                    </button>
                                </div>
                            </div>

                            {error &&
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            }
                        </form>
                    </>
                }
                
                {confirmChangeMode &&
                    <>
                        <form onSubmit={handleCompleteForgotPasswordSubmit}>
                            <div className="alert alert-primary" role="alert">
                                <p>{CS.pages.forgotPassword.messages.complete.part1}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="form-label">{CS.labels.verificationCode}</label>
                                <input name="verificationCode" className="form-control" autoCapitalize="none" type="text" value={verificationCode} onChange={e => setVerificationCode(e.target.value!)} />
                            </div>

                            <div className="mb-md-12">
                                <label className="form-label">{CS.pages.forgotPassword.newPassword}</label>
                                <PasswordToggle password={password} setPassword={setPassword} />
                            </div>

                            <div className="mb-md-12">
                                <label className="form-label">{CS.pages.forgotPassword.checkPassword}</label>
                                <PasswordToggle password={passwordValidate} setPassword={setPasswordValidate} />
                            </div>

                            <div className="col-md-12">
                                <br />
                                <div className="d-grid gap-2">
                                <button disabled={!busy && (!email || !passwordisValid || !passwordMatch || !isCaptchValid)} type="submit" className="btn btn-primary">
                                        {CS.pages.forgotPassword.update}
                                    </button>
                                </div>
                            </div>

                            {error &&
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            }

                            <br />

                            <PasswordRules 
                                password={password}
                                passwordValidate={passwordValidate}
                                setPasswordisValid={setPasswordisValid} 
                                passwordMatch={passwordMatch}
                                setPasswordMatch={setPasswordMatch}
                            />
                        </form>
                    </>
                }


                <hr />

                <div className="col-md-12">
                    <div className="loginRegister-panel-body-other">
                        {!confirmChangeMode && (timeout === TIMEOUT_RESET) &&
                            <>
                                <NavLink onClick={() => changeMode(LoginMode.LOGIN)}  to="#">{CS.labels.login}</NavLink>
                                {(!isTestEnv() || isLocalEnv()) && 
                                    <NavLink onClick={() => changeMode(LoginMode.REGISTER)}  to="#">{CS.labels.register}</NavLink>
                                }
                            </>
                        }
                        
                        {confirmChangeMode &&
                            <span className="loginRegister-panel-body-other-tomeout">{CS.pages.forgotPassword.askAgain} {timeout}s</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

import { 
    approveStone as approveStoneSrv, 
    Stone, 
} from "@root/service/stoneService";

export async function increaseLikes(currentUser: any, item: Stone) {
    if (!!~item.likes.indexOf(currentUser.uid)) {
        item.likes.splice(item.likes.indexOf(currentUser.uid), 1);
    } else {
        item.likes.push(currentUser.uid);
    }
    // try {
    //     debugger;
    //     await updateLikes(item, currentUser);
    // } catch (e) {
    //     item.likes.splice(item.likes.indexOf(currentUser.uid), 1);
    //     onIncreaseLikes((stones: any) => [...stones]);
    // }
}
export async function approveStone(item: Stone) {
    return approveStoneSrv(item);
    // try {
    // } catch (e) {        
    // }
}

import { BsFillTrashFill } from 'react-icons/bs';

import ZipSelector from '../../components/ZipSelector';
import CS from '@root/i18n/cs';


export default function ImageItem({ index, item, zipChanged, selectedZipList, remove }: any) {
	const { image } = item;

	return (
		<div className="card p-1">
			<h1>{CS.components.imageItem.title} {index + 1}</h1>
			<img src={URL.createObjectURL(image)} className="card-img-top" alt="img" />

			<div className="card-body">
				{/* {moment(item.lastModified).format('MMMM Do YYYY, hh:mm')} */}

				<div className="alert alert-primary" role="alert">
					{CS.components.imageItem.zipInfo}					
				</div>
				<ZipSelector
					items={selectedZipList}
					displayAll={true}
					ignoreFilter={true}
					changed={zipChanged}
				/>

				<br />
				<div className="d-flex flex-row-reverse">
					<button className='btn btn-danger' onClick={() => remove()} >
						<BsFillTrashFill /> {CS.components.imageItem.remove}
					</button>
				</div>
			</div>



		</div>
	);
};
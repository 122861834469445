import "./inspectStones.scss"
import { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from 'react-router-dom';
import CS from '@root/i18n/cs';

import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from '../../contexts/AuthContext';
import { useStore } from '../../contexts/Store';
import StoneContent from '@root/components/StoneContent';

import { getStones, approveStoneInspection, Stone, GetStonesType } from "@root/service/stoneService";
import { SearchOptions } from '../../actions/actions';

import { FiSave } from 'react-icons/fi';

import { StoneState } from '@root/helpers/constants';

import { sendMessage } from '@root/helpers/errorHandling';

export default function InspectStones() {
    const [busy, setBusy] = useState(true);
    const [stones, setStones] = useState<any>([]);
    const [lastKey, setLastKey] = useState<string>();
    const [forceUpdate, setForceUpdate] = useState<number | undefined>();

    const [stonePointers, setStonePointers] = useState<any>([]);

    const [searchParams, setSearchParams] = useState<SearchOptions>();

    useEffect(() => window.scroll({ top: 0, behavior: 'smooth' }) , []);
    
    const { currentUser, refreshUser } = useAuth();
    const { state } = useStore();
    const location = useLocation();

    const showMyStones = location.search.toLocaleLowerCase().indexOf('?my') >= 0;
    const [userName] = useState(showMyStones ? currentUser.uid : '');



    async function filterStones(search: SearchOptions = {}, lastKey: any = undefined) {
        setBusy(true);
        search = { ...search, state: StoneState.PUBLIC };

        return getStones({ search, key: lastKey }, GetStonesType.INSPECT_STONES).then(resp => {
            setLastKey(resp.lastKey)

            let array = [...stones, ...resp.stones.filter((stone: Stone) => {
                return stones.filter((s: Stone) => s.trackingId === stone.trackingId).length === 0;
            })].sort((a: Stone, b: Stone) => {
                return b.timestamp - a.timestamp;
            })
            setStones(array);

            setBusy(false);
        });
    }

    useEffect(() => {
        if (showMyStones) {
            const search = { userName };
            setSearchParams(search);
            filterStones(search);
        } else {
            filterStones(state.search);
        }
        // filterStones(state.search);
    }, [state, forceUpdate]);

    

    async function approveInspectionHandler(item: Stone) {
        setStones([]);
        setBusy(true);
        try {
            // item.stones.data.map((stone:any) => {
            //     debugger;
            // })
            // console.log(item);
            // stonePointers.filter((item: any) => item);
            const stonePointersFiltered = stonePointers
                .filter((item: any) => item)
                .filter((pointerItem: any) => item.stones.find((stone: any) => stone.stoneId === pointerItem.stoneId));

            await approveStoneInspection(item.trackingId, stonePointersFiltered);
            await refreshUser();
            setForceUpdate(+new Date());
            setBusy(false);
            // dispatch(searchStone({}));


            // document.location.reload();
        } catch(e) {
            sendMessage(currentUser.displayName, 'Cannot approve stone');
            setBusy(false);
        }
    }

    return (
        <div className="container" id="approveStones">
            <br />

            <div className="alert alert-info" role="alert">
                <h4 className="alert-heading">{CS.pages.inpsectStone.header}</h4>
                <p>{CS.pages.inpsectStone.description}</p>
                <hr />
                <div className="mb-0">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                {CS.pages.inpsectStone.totalCountToApprove}
                            </div>
                            <span className="badge bg-primary rounded-pill">{stones.length}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <InfiniteScroll
                dataLength={stones.length}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                next={() => {
                    filterStones(searchParams, lastKey);
                }}
                hasMore={false}
                loader={null}
            >
                <div className="row justify-content-md-center">
                    {stones.map((item: Stone, index: any) => (
                        <StoneContent 
                            index={index} 
                            key={index}
                            item={item}
                            stones={stones}
                            setStones={setStones}
                            disableActions={true}
                            anotate={true}
                            setBusy={setBusy}
                            additionalContent={
                                <div className="alert alert-primary" role="info">
                                    <h6>{CS.pages.inpsectStone.stoneParameters}</h6>

                                    <ul className="list-group">
                                        <li className="list-group-item list-group-item-info" key={index}>
                                            {CS.pages.inpsectStone.stoneCount}&nbsp;
                                            {stonePointers.filter((item: any) => item).filter((pointerItem: any) => item.stones.find((stone: any) => stone.stoneId === pointerItem.stoneId)).length}
                                        </li>
                                    </ul>
                                </div>
                            }
                            customActions={() => {
                                return stonePointers.filter((item: any) => item).filter((pointerItem: any) => item.stones.find((stone: any) => stone.stoneId === pointerItem.stoneId)).length > 0 &&
                                    <>
                                        <div className={"col action-buttons-item"}
                                            onClick={() => approveInspectionHandler(item)} >
                                            <FiSave size={28} /><span>{CS.labels.save}</span>
                                        </div>

                                        {/* <div className="col action-buttons-item delete" onClick={() => setShowRejectionReason(item) }>
                                            <MdOutlineCancel size={28} color="red" />
                                            <span>{CS.labels.cancel}</span>
                                        </div> */}
                                    </>
                                ;
                            }}
                            stonePointersHook={[stonePointers, setStonePointers]}
                        />
                    ))}
                </div>
            </InfiniteScroll>
            {busy &&
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }

            {!busy && stones && !stones.length &&
                <div className="alert alert-primary" role="alert">
                    {CS.messages.warning.emptyApproveList}
                </div>
            }

            <br />
            <br />
        </div>
    );
};

import "./AddStone.scss";
import React, { useState, useEffect } from 'react';
import { GoPlus } from 'react-icons/go';
import { useNavigate, useLocation } from 'react-router-dom';
import CS from '@root/i18n/cs';

import { useAuth } from '@root/contexts/AuthContext';
import { addNewStone, ZipItem, ImageData } from "@root/service/stoneService";
import ImageItem from './ImageItem';
import { 
    ZipSelector,
    MapHolder
} from '@root/components';

// import ZipSelector2 from '@root/components/zipSelector/ZipSelector';

import { StoneOwner } from '@root/helpers/constants';
import { AiFillLock } from 'react-icons/ai';
import { MdPublic } from 'react-icons/md';

import { MapPosition } from '@root/helpers/definitions';
import { Limits } from '@root/helpers/constants';

import { useMessageManager } from '@root/contexts/MessageManager';
import {
    ConfirmTemplates
} from '@root/templates/confirmTemplates';
import { showMessage, showConfirmDialog } from '@root/actions/messageManager-actions';

export default function AddStone() {
    const [busy, setBusy] = useState(false);

    const { stateMessages , dispatchMessages } = useMessageManager();

    const [stoneCreated, setStoneCreated] = useState(false);
    const [position, setPosition] = useState<MapPosition>({} as MapPosition);
    const [positionData, setPositionData] = useState('');

    const [stoneOwner, setStoneOwner] = useState<StoneOwner>(StoneOwner.UNKNOWN);
    const [images, setImages] = useState<ImageData[]>([]);
    const [description, setDescription] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState<any>();
    const [selectedZipList, setSelectedZIPList] = useState<ZipItem[]>([]);
    const location = useLocation();

    useEffect(() => window.scroll({ top: 0, behavior: 'smooth' }) , []);

    useEffect(() => {
        setStoneCreated(false);
        setImages([]);
        setSelectedZIPList([]);
        window.scroll({ top: 0, behavior: 'smooth' });
    }, [location]);

    const { currentUser, refreshUser } = useAuth();
    const navigate = useNavigate();

    // actions
    // delete stone
    const [confirmDialogId, setConfirmDialogId] = useState(0);
    useEffect(() => {
        (async () => {
            if (stateMessages.confirmDialog && stateMessages.confirmDialog.confirmed) {
                if (stateMessages.confirmDialog.id === confirmDialogId) {
                    setBusy(true);
                    // const { meta: timestamp } = stateMessages.confirmDialog;
                    try {
                        await save();
                        setBusy(false);
                    } catch(error: any) {
                        setBusy(false);
                        dispatchMessages(showMessage({
                            type: 'error',
                            message: error.message
                        }));
                    }
                }
            }
        })();
    }, [stateMessages]);

    const removeImage = (image: any) => {
        setImages((images: any) => {
            const data = [...images];
            data.splice(data.indexOf(image), 1);
            return data;
        });
    }

    function handleFileClick(e: any) {
        setFileErrorMessage(null);

        if (e.target.files && e.target.files.length > 1) {

            if (e.target.files.length > 20) {
                setFileErrorMessage(<>{CS.pages.addNewStone.messages.warning.maxImages}</>);
                return;
            }

            if (Array.from(e.target.files).find((file: any) => file['type'].split('/')[0] !== 'image')) {
                setFileErrorMessage(<>Nepovolený vstupní soubor. Můžete nahrát pouze soubory s příponou <b>jpg, jpeg, png	</b></>);
            } else {
                const newImages = Array.from(e.target.files).map((file: any) => new ImageData(file));
                setImages((images: any) => [...images, ...newImages]);
            }

        } else {
            const file = e.target.files![0];
            if (file && file['type'].split('/')[0] === 'image') {
                setImages((images: any) => [...images, new ImageData(file)]);
            } else {
                setFileErrorMessage(<>Nepovolený vstupní soubor. Můžete nahrát pouze soubory s příponou <b>jpg, jpeg, png	</b></>);
            }
        }

    }

    async function save() {
        if (stoneOwner) {
            setBusy(true);
            await addNewStone({
                stoneOwner: stoneOwner,
                zipList: selectedZipList,
                images: images,
                description,
                userUid: currentUser.uid,
                position,
                positionData
            }).then(async () => {
                setBusy(false);
                if (stoneOwner === StoneOwner.YOU) {
                    setStoneCreated(true);
                    await refreshUser();
                    navigate('/stones/private');
                } else if (currentUser['cognito:groups'] && currentUser['cognito:groups'].indexOf('AutoApprove') >=0) {
                    setStoneCreated(true);
                    await refreshUser();
                    navigate('/stones');
                } else {
                    if (stoneOwner === StoneOwner.UNKNOWN) {
                        setStoneCreated(true);
                        await refreshUser();
                        navigate('/addstone?created');
                    } else {
                        setStoneCreated(true);
                        await refreshUser();
                        navigate('/stones/private');
                    }
                }
            }).catch(() => {
                setBusy(false);
                dispatchMessages(showMessage({
                    type: 'error',
                    message: CS.pages.addNewStone.messages.error.createIssue
                }));    
            })

        } else {
            alert('stoneOwner not defined!');
        }
    }

    async function handleSave(e: React.SyntheticEvent) {
        e.preventDefault();

        if (stoneOwner) {
            if (!position.lat && stoneOwner === StoneOwner.UNKNOWN) {
                const id = +new Date();
                setConfirmDialogId(id);
                dispatchMessages(showConfirmDialog(id, ConfirmTemplates.MISSING_GPS));
            } else {
                save();
            }
        }
    }

    function handleStoneOwner(stoneOwner: StoneOwner) {
        setStoneOwner(stoneOwner);
    }

    return (
        <div className="container" id="addStone">
            {busy &&
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }
            <br />

            {!busy && !stoneCreated &&
                <>
                    <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">{CS.pages.addNewStone.header}</h4>
                        <br />
                        <div className="form-check">
                            <label className="form-check-label">
                                <input className="form-check-input" type="radio" value={StoneOwner.UNKNOWN} name="owner"
                                    checked={stoneOwner === StoneOwner.UNKNOWN}
                                    onChange={() => handleStoneOwner(StoneOwner.UNKNOWN)}
                                />
                                <MdPublic size={28} />&nbsp;
                                <b>{CS.pages.addNewStone.owner.unknown.title} </b> {CS.pages.addNewStone.owner.unknown.descripton}
                            </label>
                        </div>
                        <br />
                        <div className="form-check">
                            <label className="form-check-label">
                                <input className="form-check-input" type="radio" value={StoneOwner.YOU} name="owner"
                                    checked={stoneOwner === StoneOwner.YOU}
                                    onChange={() => handleStoneOwner(StoneOwner.YOU)}
                                />
                                <AiFillLock size={28} />&nbsp;
                                <b>{CS.pages.addNewStone.owner.you.title} </b> {CS.pages.addNewStone.owner.you.descripton}
                            </label>
                        </div>
                    </div>

                    {stoneOwner &&
                        <>
                            {stoneOwner === StoneOwner.UNKNOWN &&
                                <div className="alert alert-primary" role="alert">
                                    <p>{CS.pages.addNewStone.owner.unknown.wizard.instructions}</p>
                                    
                                    <p className="mb-0">
                                        <b>{CS.pages.addNewStone.owner.unknown.wizard.position.title}</b> 
                                    </p>
                                </div>
                            }
                            
                            {stoneOwner === StoneOwner.UNKNOWN &&
                                <>
                                    <MapHolder 
                                        position={position}
                                        setPosition={setPosition}
                                        positionData={positionData}
                                        setPositionData={setPositionData}
                                    />

                                    <br />

                                    <div className="alert alert-primary" role="alert">
                                        <p>
                                            {CS.pages.addNewStone.owner.unknown.wizard.instructionFilter}
                                        </p>
                                    </div>
                                </>
                            }


                            <ZipSelector
                                title={stoneOwner === StoneOwner.UNKNOWN ?
                                    CS.pages.addNewStone.owner.unknown.wizard.zipInfo :
                                    CS.pages.addNewStone.owner.you.wizard.zipInfo
                                }
                                changed={(items: ZipItem[]) => {
                                    setSelectedZIPList(items);
                                }}
                            />

                            {/* <ZipSelector2 /> */}
                            <br />
                            <br />
                            {selectedZipList.length > 0 &&
                                <form onSubmit={handleSave}>
                                    <div className={images.length > 0 ? "dimmed" : ''}>
                                        <br />
                                        <div className="alert alert-primary" role="alert">
                                            <h4 className="alert-heading">{CS.pages.addNewStone.images.title}</h4>
                                            <p>{CS.pages.addNewStone.images.wizard.title} <b>{CS.pages.addNewStone.images.wizard.button}</b> {CS.pages.addNewStone.images.wizard.instructions}</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-md-center">
                                        {images && images.map((item: ImageData, index: number) => {
                                            return (
                                                <div className="col-sm-12 col-lg-6" key={index}>
                                                    <ImageItem
                                                        remove={removeImage}
                                                        index={index}
                                                        item={item}
                                                        selectedZipList={selectedZipList}
                                                        zipChanged={(items: any) => {
                                                            setImages((images: any) => {
                                                                const data = [...images];
                                                                const updateItem = data[data.indexOf(item)];
                                                                updateItem.zipList = items;
                                                                return data;
                                                            })
                                                        }}
                                                    ></ImageItem>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {images && images.length > 0 &&
                                        <>
                                            <br />
                                        </>
                                    }

                                    {images.length > 20 &&
                                        <div className="alert alert-primary" role="alert">{CS.pages.addNewStone.messages.warning.maxImages}</div>
                                    }

                                    <button disabled={images.length > 20} className='btn btn-primary' type="button" onClick={() => {
                                        const item = document.querySelector('#file-upload');
                                        (item as any).click();
                                    }}>
                                        <GoPlus /> {CS.pages.addNewStone.images.addImage}
                                    </button>

                                    <input
                                        className='hidden-element'
                                        type="file"
                                        multiple
                                        id="file-upload"
                                        onChange={handleFileClick}
                                    />

                                    {fileErrorMessage &&
                                        <>
                                            <br /><br />
                                            <div className="alert alert-danger" role="alert">
                                                {fileErrorMessage}
                                            </div>
                                        </>
                                    }

                                    <br />
                                    <br />

                                    <div className="card p-3">
                                        <div className="mb-3">
                                            <label htmlFor="note" className="form-label">
                                                {CS.pages.addNewStone.note.replace('%', (Limits.NOTE_MAX_LENGTH - description.length).toString())}
                                            </label>
                                            <textarea
                                                maxLength={Limits.NOTE_MAX_LENGTH}
                                                value={description}
                                                onChange={e => setDescription(e.target.value!)}
                                                className="form-control" id="note" rows={3}></textarea>
                                        </div>

                                        {/* <div className="mb-3">
                                            <label htmlFor="stoneCount" className="form-label">{CS.pages.addNewStone.stoneCount}</label>
                                            <br />

                                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                {totalStoneList.map((item: any, index: number) =>
                                                    <React.Fragment key={index}>
                                                        <input type="radio" className="btn-check" name="btnradio" onChange={() => {
                                                            setStoneCount(item);
                                                        }} autoComplete="off" checked={item === stoneCount} />
                                                        <label className="btn btn-outline-primary"
                                                            onClick={e => {
                                                                setStoneCount(item);
                                                            }}
                                                        >{item}</label>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div> */}

                                        <br />

                                        {/* {stoneCount > 0 && stoneCount < selectedZipList.length &&
                                            <div className="alert alert-danger" role="alert">
                                                {CS.pages.addNewStone.messages.warning.ensureStoneCount
                                                    .replace('{}', '' + selectedZipList.length)
                                                    .replace('{}', stoneCount)
                                                }
                                            </div>
                                        } */}
                                        {/* {stoneCount < 1 && !images.length &&
                                            <div className="alert alert-warning" role="alert">
                                                Alespoň 1 kamínek máte, ne?
                                            </div>
                                        } */}
                                        {!busy &&
                                            <button
                                                type="submit"
                                                className={'btn btn-success' + (!images.length ? ' dimmed' : '')}
                                                disabled={!selectedZipList.length || !images.length}>{CS.labels.save}</button>
                                        }

                                        <br />
                                        <br />
                                    </div>
                                </form>
                            }
                        </>
                    }
                </>
            }

            {stoneCreated &&
                <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">{CS.pages.addNewStone.success.title}</h4>
                    <p>{CS.pages.addNewStone.success.instructions}</p>
                    <hr />
                    <p className="mb-0">
                        {CS.pages.addNewStone.success.why}&nbsp;
                        <a href="#/stones">{CS.labels.stones}</a>
                    </p>

                    <a href="#/addstone" onClick={() => {
                        document.location.reload();
                    }}>{CS.pages.addNewStone.newStone}</a>
                </div>
            }

            <br />
            <br />

        </div>
    );
};

import "./Comments.scss";
import React, { useEffect, useState } from 'react';
import CS from '@root/i18n/cs';

import { useAuth } from '@root/contexts/AuthContext';
import { useStore } from '@root/contexts/Store';
import { showMessage, showConfirmDialog } from '@root/actions/messageManager-actions';
import { FiSave } from 'react-icons/fi';
import { FaTrash } from 'react-icons/fa';
import { BsPencil } from 'react-icons/bs';
import { GiCancel } from 'react-icons/gi';
import { updateStoneComment, removeStoneComment } from "@root/service/stoneService";
import { CommentItem } from '@root/helpers/definitions';
import { Roles } from '@root/helpers/constants';

import { useMessageManager } from '@root/contexts/MessageManager';
import {
    ConfirmTemplates
} from '@root/templates/confirmTemplates';

interface CommentsProps {
    stone: any;//Stone;
    // stones: Stone[];
    // setStones: Dispatch<SetStateAction<Stone[]>>,
}

export default function Comments({ ...rest }: CommentsProps) {
    const {
        stone,
    } =  rest;

    const [busy, setBusy] = useState(false);
    const { dispatch } = useStore();
    const { stateMessages , dispatchMessages } = useMessageManager();

    const [comments, setComments] = useState<CommentItem[]>(stone.comments);

    const [comment, setComment] = useState('');
    const { currentUser } = useAuth();

    const [confirmDialogId, setConfirmDialogId] = useState(0);
    // delete stone
    useEffect(() => {
        (async () => {
            if (stateMessages.confirmDialog && stateMessages.confirmDialog.confirmed) {
                if (stateMessages.confirmDialog.id === confirmDialogId) {
                    setBusy(true);
                    const { meta: timestamp } = stateMessages.confirmDialog;
                    try {
                        const comments = await removeStoneComment(stone.trackingId, timestamp);
                        if (comments) {
                            setBusy(false);
                            setComments(comments);
                        }
                    } catch(error: any) {
                        setBusy(false);
                        dispatch(showMessage({
                            type: 'error',
                            message: error.message
                        }));
                    }
                }
            }
        })();
    }, [stateMessages])


    async function createCommentHandler(e: React.SyntheticEvent) {
        e.preventDefault();
        setBusy(true);
        try {
            const comments = await updateStoneComment(stone.trackingId, comment);
            if (comments) {
                setBusy(false);
                setComments(comments);
                setComment('');
            }
        } catch(error: any) {
            setBusy(false);
            dispatch(showMessage({
                type: 'error',
                message: error.message
            }));
        }
    }

    async function updateComment(comment: CommentItem) {
        setBusy(true);
        try {
            const comments = await updateStoneComment(stone.trackingId, comment.message, comment.timestamp);
            if (comments) {
                setBusy(false);
                setComments(comments);
            }
        } catch(error: any) {
            setBusy(false);
            dispatch(showMessage({
                type: 'error',
                message: error.message
            }));
        }
    }

    async function removeComment(isUserStoneOwner: boolean, timestamp: number) {
        let template = isAdmin && !isUserStoneOwner ? ConfirmTemplates.ADMIN_REMOVE_COMMENT : ConfirmTemplates.REMOVE_COMMENT;

        const id = +new Date();
        setConfirmDialogId(id);

        dispatchMessages(showConfirmDialog(id, template, timestamp));
    }

    // async function reportCommentHandler(trackingId: string, timestamp: number) {
    //     // dispatch(showConfirmDialog());
    // }

    const isAdmin = currentUser.info.user.role === Roles.ADMIN;

    return (
        <div className="comments">

            {/* <ConfirmDialog
                show={true}
                onHide={(confirmState: ConfirmState) => { debugger;}}
                title="Varovani"
                description="Opravu si prejete odstranit komentar?"

            ></ConfirmDialog> */}

            {busy &&
                <>
                    <br />
                    <div className="busy-indicator-wrapper">
                        <div className="busy-indicator">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>
                </>
            }

            {!busy &&
                <div className="comments-panel">
                    <div className="comments-panel-comment">
                        <form onSubmit={createCommentHandler}>
                            <textarea 
                                className="form-control" id="note" placeholder={CS.components.comments.postComment}
                                value={comment}
                                required={true}
                                onChange={e => setComment(e.target.value!)}
                                maxLength={1000}
                                rows={1}>
                            </textarea>
                            <button type="submit" className={"btn btn-link" + 
                                (comment.length === 0 ? ' disabled' : '')}>{CS.components.comments.submit}</button>
                        </form>
                    </div>
                    <ul className="comments-panel-contribution">
                        {comments.map((item: CommentItem, index) => {
                            const isUserStoneOwner = item.uid === currentUser.info.user.userId;

                            return (
                                <li className="comments-panel-contribution-item-wrapper" key={index}>
                                    <div className="comments-panel-contribution-item-wrapper-holder">

                                        {item.user?.photoURL &&
                                            <span className="comments-panel-contribution-item-wrapper-holder-avatar">
                                                <img src={item.user?.photoURL} alt="Avatar"></img>
                                            </span>
                                        }
                                        
                                        {!item.user?.photoURL &&
                                            <span className="comments-panel-contribution-item-wrapper-holder-avatar">
                                                <img src="./images/user.png" alt="Avatar"></img>
                                            </span>
                                        }

                                        <div className="comments-panel-contribution-item-wrapper-holder-body">
                                            <div className="comments-panel-contribution-item-wrapper-holder-body-user">
                                                {item.user?.userName}
                                            </div>

                                            {!item.edit &&
                                                <div className="comments-panel-contribution-item-wrapper-holder-body-content">
                                                    {item.message}
                                                </div>
                                            }

                                            {item.edit &&
                                                <textarea 
                                                    className="form-control" id="note" placeholder={CS.components.comments.postComment}
                                                    value={item.message}
                                                    required={true}
                                                    onChange={e => {
                                                        item.message = e.target.value;
                                                        setComments([...comments]);
                                                    }}
                                                    maxLength={1000}
                                                    rows={1}>
                                                </textarea>
                                            }

                                            {(item.uid === currentUser.info.user.userId || isAdmin && !item.edit) &&
                                                <div className="comments-panel-contribution-item-wrapper-holder-body-actions">
                                                    {/* {item.uid !== currentUser.info.user.userId &&
                                                        <span onClick={() => reportCommentHandler(stone.trackingId, item.timestamp)}>
                                                            <CgSmileSad></CgSmileSad>&nbsp;nahlasit jako nevhodny
                                                        </span>
                                                    } */}

                                                    {item.uid === currentUser.info.user.userId &&
                                                        <>
                                                            {!item.edit &&
                                                                <>
                                                                    <span onClick={() => {
                                                                        item.edit = !item.edit;
                                                                        if (!item.prevMessage) {
                                                                            item.prevMessage = item.message;
                                                                        }
                                                                        setComments([...comments]);
                                                                    }}>
                                                                        <BsPencil></BsPencil>&nbsp;{CS.components.comments.edit}
                                                                    </span>
                                                                </>
                                                            }

                                                            {item.edit &&
                                                                <>
                                                                    <span onClick={() => updateComment(item) }>
                                                                        <FiSave title={CS.components.comments.saveText}/>&nbsp;{CS.components.comments.save}
                                                                    </span>

                                                                    <span onClick={() => removeComment(isUserStoneOwner, item.timestamp) }>
                                                                        <FaTrash title={CS.components.comments.deleteText}/>&nbsp;{CS.components.comments.delete}
                                                                    </span>

                                                                    <span onClick={() => {
                                                                        item.edit = !item.edit;
                                                                        item.message = item.prevMessage!;
                                                                        setComments([...comments]);
                                                                    }}>
                                                                        <GiCancel title={CS.components.comments.cancelText}/>&nbsp;{CS.components.comments.cancel}
                                                                    </span>
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                    {isAdmin && !item.edit && isUserStoneOwner &&
                                                        <span onClick={() => removeComment(isUserStoneOwner, item.timestamp) }>
                                                            <FaTrash color="red" title={CS.components.comments.deleteText}/>&nbsp;{CS.components.comments.delete}
                                                        </span>                                                
                                                    }

                                                    {isAdmin && !item.edit && !isUserStoneOwner &&
                                                        <span onClick={() => removeComment(isUserStoneOwner, item.timestamp) }>
                                                            <FaTrash color="orange" title={CS.components.comments.deleteText}/>&nbsp;{CS.components.comments.delete}
                                                        </span>                                                
                                                    }
                                                </div>
                                            }

                                        </div>
                                
                                    </div>

                                    {item.timestamp &&
                                        <div className="comments-panel-contribution-item-wrapper-date">
                                            {item.date}
                                        </div>
                                    }

                                </li>
                            );
                        })}
                    </ul>
                </div>
            }
        </div>
    )
}
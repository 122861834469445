import "./StoneGallery.scss"
import React, { Dispatch, SetStateAction } from 'react';
import Gallery from "react-photo-gallery";
import { ImCross } from 'react-icons/im';
import { GiStoneStack } from 'react-icons/gi';

import { useAuth } from '@root/contexts/AuthContext';
import { StonePointer } from "@root/service/stoneService";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Button from 'react-bootstrap/Button';

export interface StoneImageContainerProps {
    photos: any;
    anotate?: boolean;
    stonePointersHook?: [any, Dispatch<SetStateAction<any>>];
    myStoneHandler?: (pointer: any) => void;
}

export default function StoneGallery({ ...rest }: StoneImageContainerProps) {
    const { 
        photos, 
        anotate,
        stonePointersHook,
        myStoneHandler
    } = rest;

    const [stonePointers, setStonePointers] = stonePointersHook || [[], () => {}];
    const { currentUser } = useAuth();

    const renderImage = ({ ...rest }: any) => {
        // const renderImage = ({ index, left, top, key, photo }: any, pointers: any, setPointers: any) => {

        const { index, photo } = rest;
        const { stoneId, ...photoParams } = photo;
        // const { pointers, setPointers, ...photoParams } = photo;
            
        function handleImageClick(event: any) {
            if (!(event.target instanceof  Image) || !anotate) {
                const data = [...stonePointers];
                data.forEach(item => {
                    item.tooltipVisible = false;
                })
                setStonePointers(data);
                return;
            }

            const rect = event.target.getBoundingClientRect();
            let left = event.clientX - rect.left + event.target.offsetLeft - 19;
            let top = event.clientY - rect.top + event.target.offsetTop - 19;

            const leftPx = Math.round(left / (event.target.width / 100) * 100) / 100;
            const topPx = Math.round(top / (event.target.height / 100) * 100) / 100;

            setStonePointers([ ...stonePointers.filter((x: any) => x), {
                left: leftPx,
                top: topPx,
                key: +new Date(),
                stoneId: stoneId
            }]);
        }

        function removePointeHandler(e:any, pointer: any) {
            if (anotate) {
                const data = [...stonePointers];
                data.splice(data.indexOf(pointer), 1, null);

                console.log(data);
                setStonePointers(data);
                e.preventDefault();
                e.stopPropagation();
            } else {
                const data = [...stonePointers];
                const item = data[data.indexOf(pointer)];
                data.forEach(item => {
                    item.tooltipVisible = false;
                })
                item.tooltipVisible = !item.tooltipVisible;
                setStonePointers(data);
                e.preventDefault();
                e.stopPropagation();
            }
        }

        return (
            <span key={index} className="image-wrapper" onClick={handleImageClick}>
                {stonePointers.filter((item: any) => item && item.stoneId === photo.stoneId).map((pointer: StonePointer, index: number) => {
                    if (!pointer) {
                        return null;
                    }
                    
                    return (
                        <React.Fragment key={index}>
                            <div onClick={(e) => removePointeHandler(e, pointer) } key={index} 
                                className={"image-location" + (pointer.user ? " user" : "")} style={{ 
                                left: pointer.left + '%', top: pointer.top + '%' 
                            }} >

                                {!anotate &&
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger="click"
                                        show={pointer.tooltipVisible}
                                        overlay={(props: any) => (
                                            <Tooltip id="button-tooltip" {...props}>
                                                <div className="image-pointer-tooltip" id={`tooltip${index}`}>
                                                    {pointer.name &&
                                                        <span className="image-pointer-tooltip-title">{pointer.name}</span>
                                                    }
                                                    {pointer.user &&
                                                        <div className="image-pointer-tooltip-body">
                                                            <span className="image-pointer-tooltip-body-avatar">
                                                                <img src={pointer.user?.photoURL} alt="Avatar"></img>
                                                            </span>
                                                            <div className="image-pointer-tooltip-body-content">od {pointer.user.userName}</div>
                                                        </div>
                                                    }

                                                    {!pointer.user && myStoneHandler &&
                                                        <div className="image-pointer-tooltip-body">
                                                            <Button onClick={() => myStoneHandler(pointer)}>
                                                                <GiStoneStack /> Označ jako můj kamínek.
                                                            </Button>
                                                        </div>
                                                    }
                                                    {pointer.user && pointer.user.userId === currentUser.info.user.userId && myStoneHandler &&
                                                        <div className="image-pointer-tooltip-body">
                                                            <Button onClick={() => myStoneHandler(pointer)}>
                                                                <GiStoneStack /> Upravit muj kamínek.
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </Tooltip>
                                        )}
                                        >
                                        
                                        <Button className="image-location-button">
                                            <span className="image-location-button-content">
                                                <ImCross size={14}/>
                                            </span>
                                        </Button>
                                        
                                    </OverlayTrigger>
                                }

                                {anotate &&
                                    <Button className="image-location-button">
                                        <span className="image-location-button-content">
                                            <ImCross size={14}/>
                                        </span>
                                    </Button>
                                }
                                
                                {/* {pointer.tooltipVisible &&
                                    
                                } */}
                            </div>
                        </React.Fragment>
                    );
                })}
                <img
                    style={{ 'margin': '2px', 'display': 'block', 'cursor': 'pointer' }}
                    alt={photo.title}
                    {...photoParams}
                />

            </span>
        );
    }

    return (
        <Gallery 
            renderImage={renderImage} 
            photos={photos}
        />

        // <span key={index} className="image-wrapper" onClick={handleImageClick}>
        //     {pointers.map((pointer: any, index: number) => {
        //         return (
        //             <ImCross onClick={(e) => removePointeHandler(e, pointer) } key={index} size={28} id="cross" className="image-location" style={{ 
        //                 left: pointer.left + '%', top: pointer.top + '%' 
        //             }} />
        //         );
        //     })}
        //     <img
        //         style={{ 'margin': '2px', 'display': 'block', 'cursor': 'pointer' }}
        //         alt={photo.title}
        //         {...photoParams}
        //     />

        // </span>
    );
};


export async function reduceImageSize(image: File | Blob): Promise<any> {
    return new Promise((resolve) => {
        const MAX_WIDTH = 1280;
        const MAX_HEIGHT = 720;
        const QUALITY = 0.6;
        const MIME_TYPE = "image/jpeg";

        const blobURL = URL.createObjectURL(image);
        const img = new Image();
        img.src = blobURL;


        function calculateSize(img: any, maxWidth: number, maxHeight: number) {
            let width = img.width;
            let height = img.height;

            // calculate the width and height, constraining the proportions
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }
            return [width, height];
        }

        img.onload = function () {
            const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx: any = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(outImage => {
                resolve({ image, outImage, info: { width: newWidth, height: newHeight}});
            }, MIME_TYPE, QUALITY);

            // var outImage = new Image();
            // outImage.onload = function() {

            //     const reader = new FileReader();

            //     resolve(new Blob([outImage]));
            // }
            // outImage.src = canvas.toDataURL("image/png");
        };
    })
}

export function mapMenuItem(key: string) {
    if (key === 'stones') {
        return 'Kamínky';
    } else if (key === 'addstone') {
        return 'Přidat kamínek';
    } else if (key === '') {
        return "Domu";
    }
    return key;
}

export function mapCommentCount(count: number) {
    if (count < 2) {
        return 'Komentář';
    } else if (count < 5) {
        return 'Komentáře';
    } else {
        return 'Komentářů';
    }
}

export function debounce(fn: any, delay: number) {
    var timer: any = null;
    return () => {
        debugger;
        return new Promise((resolve) => {
            var args: any = null; // arguments;
            clearTimeout(timer);
            if (!delay || delay === 0) {
                const resp = fn.apply(null, args);
                resolve(resp);
            } else 
            {
                timer = setTimeout(function () {
                    const resp = fn.apply(null, [1]);
                    resolve(resp);
                }, delay);
            }
        });
    }
}

export function isTestEnv() {
    const hostName = document.location.hostname;
    return hostName.startsWith('test.') ||
        hostName.startsWith('dev.') ||
        hostName === "localhost" ||
        hostName.startsWith('192.168.') ||
        hostName.startsWith('test-');
}

export function isLocalEnv() {
    const hostName = document.location.hostname;
    return hostName === "localhost" ||
        hostName.startsWith('192.168.');
}
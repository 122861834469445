import "./usersGallery.scss"

import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import "react-image-gallery/styles/scss/image-gallery.scss";
import Gallery from "react-photo-gallery";
import InfiniteScroll from "react-infinite-scroll-component";

import SelectedImage from './SelectedImage';
import { getLatestStones } from "@root/service/stoneService";

interface UsersGalleryProps {
    isPublic: any
}

export default function UsersGallery({ ...rest }: UsersGalleryProps) {
    const { isPublic } = rest;

    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    const [images, setImages] = useState<any>([]);
    const [lastKey, setLastKey] = useState<string>();

    function getPhotos(data: any) {
        const item = data.map((item: any) => {
            return {
                className: 'fading',
                src: item.url,
                width: item.width,
                height: item.height,
                label: item.label,
                onClick: () => {
                    navigate(`/stone/${item.trackingId}`);
                }
            };
        });
        return item;
    }

    function next(lastKey: any) {
        setBusy(true);
        getLatestStones(isPublic, lastKey).then((resp: any) => {
            setLastKey(resp.lastKey);
            setImages([...images, ...resp.images]);
            setBusy(false);
        });
    }

    useEffect(() => {
        next(null);
    }, []);

    const [selectAll] = useState(false);

    const renderImage = useCallback(
        ({ index, left, top, key, photo }: any) => (
            <SelectedImage
                selected={selectAll ? true : false}
                key={key}
                margin={"5px"}
                index={index}
                photo={photo}
                left={left}
                top={top}
            />
        ),
        [selectAll]
    );

    return (
        <div id="usersGallery">
            {/* <div className="carouselWrapper">
                <ImageGallery items={images}  infinite={true} lazyLoad={true} autoPlay={true} slideInterval={5000} />
            </div> */}

            <InfiniteScroll
                dataLength={images.length}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                next={() => {
                    next(lastKey);
                }}
                hasMore={lastKey !== undefined && !isPublic}
                loader={null}
            >
                <div className="row justify-content-md-center">
                    <div className="image">
                        <Gallery margin={5} photos={getPhotos(images)} renderImage={renderImage} />
                    </div>
                </div>

                {busy &&
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
            </InfiniteScroll>

        </div>
    )
}
const cont: any = {
    backgroundColor: "#eee",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative"
};

const SelectedImage = ({ photo, margin }: any) => {
    return (
        <div style={{ margin, height: photo.height, width: photo.width, ...cont }} className="image-content">
            <img
                alt={photo.title}
                {...photo}
            />
            {photo.label &&
                <span className="image-content-info">{photo.label}</span>
            }
        </div>
    );
};

export default SelectedImage;

import React, { useReducer, useContext } from 'react';

import { reducer, initialState } from '../reducers/reducer';
import * as actions from '../actions/actions';

import { fetchAppData } from "@root/service/stoneService";
interface Storage {
    state: any;
    dispatch: any;
    actions: any;
    appData: any;
}

const Store = React.createContext({} as Storage);

export const useStore = () => useContext(Store);

// TODO: read page data 
export function StoreProvider ({ children }: any) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const appData = fetchAppData();

    const value = {
        state, dispatch, actions, appData
    };

    return (
        <Store.Provider value={value}>
            {/* {state?.busy && 
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            } */}
            {!state?.busy && children}
        </Store.Provider>
    );
};
import "./about.scss"
// import React from 'react';
import { useAuth } from '@root/contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import UsersGallery from '@root/components/UsersGallery';
import CS from '@root/i18n/cs';

// import lenka from './images/lenka.png'
// console.log(lenka)

export default function About() {
    const { currentUser } = useAuth();

    return (
        <div className="container about" id="about">
            <div className="row">
                <br />
                <div className="about-welcome-panel">
                    <h4 className="about-welcome-panel-header">{CS.pages.about.title}</h4>
                    <hr className="about-devider"/>
                    <p>{CS.pages.about.description.part1}</p>
                    <p>{CS.pages.about.description.part2}</p>
                    <p>{CS.pages.about.description.part3}</p>
                    
                    {!currentUser?.info?.user &&                    
                        <p>{CS.pages.about.description.part4} <NavLink to="/start">{CS.labels.login.toLowerCase()}.</NavLink></p>
                    }
                </div>
            </div>


            <h4 className="about-our-team-info">{CS.pages.about.ourTeam}</h4>
            <hr className="about-devider"/>
            <p className="about-our-team-info-content">{CS.pages.about.moto}</p>
            
            <div className="row about-our-team">
                <div className="col-lg-4">
                    <div className="card">
                        <img src="images/lenka.png" className="card-img-top" alt="lenka" />
                        <div className="card-body">
                            <h3 className="card-title">{CS.pages.about.members.lenka.title}</h3>
                            <p className="card-text">{CS.pages.about.members.lenka.content}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                    <img src="images/jana.png" className="card-img-top" alt="jana" />
                    <div className="card-body">
                        <h3 className="card-title">{CS.pages.about.members.jana.title}</h3>
                        <p className="card-text">{CS.pages.about.members.jana.content}</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                    <img src="images/martina.png" className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h3 className="card-title">{CS.pages.about.members.martina.title}</h3>
                        <p className="card-text">{CS.pages.about.members.martina.content}</p>
                    </div>
                    </div>
                </div>
            </div>

            <br />
            <br />

            {!currentUser?.info?.user &&
                <div className="row">
                    <div className="card user-stones">
                        <div className="card-body">
                            <h4>{CS.pages.login.usersSone.title}</h4>
                            <p>{CS.pages.login.usersSone.description}</p>
                        </div>
                    </div>

                    <UsersGallery isPublic={true} />
                </div>
            }

        </div>
    );
};

import "./app.scss";
import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import {
    Home,
    LandingPage,
    Stones,
    StoneDetail,
    MapView,
    ApproveStones,
    InspectStones,
    AddStone,
    EditStone,
    Profile,
    ReportError,
    Privacy,
    GDPR,
    About,
    Articles
} from './pages';
import { 
    PrivateRouter,
    LoggedInRouter,
    ScrollTop
} from './components';

import { AuthProvider } from './contexts/AuthContext';
import { StoreProvider } from './contexts/Store';
import { MessageManager } from './contexts/MessageManager';
import MainMenu from './components/MainMenu';
import Footer from './components/Footer';

// import { fetchAppData } from "@root/service/stoneService";
// const appData = fetchAppData();

export default function App() {

    const busy = (
        <div className="busy-indicator-wrapper">
            <div className="busy-indicator">
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>
    );
    //test

    return (
        <AuthProvider>
            <StoreProvider>
                <div className="main-content">
                    <MessageManager>
                        <ScrollTop />
                        <MainMenu />
                        <Routes>
                            <Route path="/" element={<PrivateRouter />}>
                                <Route path='/' element={<Suspense fallback={busy}><Home /></Suspense>} />
                            </Route>
                            <Route path="/stones" element={<PrivateRouter />}>
                                <Route path='/stones/:viewType?/:uid?' element={<Suspense fallback={busy}><Stones /></Suspense>} />
                            </Route>
                            <Route path="/stone" element={<PrivateRouter />}>
                                <Route path='/stone:id' element={<Suspense fallback={busy}><StoneDetail /></Suspense>} />
                            </Route>
                            <Route path="/map" element={<PrivateRouter />}>
                                <Route path='/map:id' element={<Suspense fallback={busy}><MapView /></Suspense>} />
                            </Route>
                            <Route path="/approveStones" element={<PrivateRouter />}>
                                <Route path='/approveStones' element={<Suspense fallback={busy}><ApproveStones /></Suspense>} />
                            </Route>
                            <Route path="/inspectStones" element={<PrivateRouter />}>
                                <Route path='/inspectStones' element={<Suspense fallback={busy}><InspectStones /></Suspense>} />
                            </Route>
                            <Route path="/addstone" element={<PrivateRouter />}>
                                <Route path='/addstone' element={<Suspense fallback={busy}><AddStone /></Suspense>} />
                            </Route>
                            <Route path="/editstone" element={<PrivateRouter />}>
                                <Route path='/editstone:id' element={<Suspense fallback={busy}><EditStone /></Suspense>} />
                            </Route>
                            <Route path="/report" element={<PrivateRouter />}>
                                <Route path='/report' element={<Suspense fallback={busy}><ReportError /></Suspense>} />
                            </Route>
                            <Route path="/profile" element={<LoggedInRouter />}>
                                <Route path='/profile' element={<Suspense fallback={busy}><Profile /></Suspense>} />
                            </Route>
                            <Route path="/start" element={<LoggedInRouter reverse={true} />}>
                                <Route path='/start' element={<Suspense fallback={busy}><LandingPage /></Suspense>} />
                            </Route>
                            <Route path='/about' element={<About />} />
                            <Route path='/articles' element={<Articles />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/gdpr" element={<GDPR />} />
                        </Routes>

                    </MessageManager>
                </div>

                <Footer></Footer>
            </StoreProvider>
        </AuthProvider>
    )
};

import "./profile.scss"
import React, { useState, useCallback } from "react";
import { 
    useAuth, 
    isExistingUser, 
    createOrUpdateUser, 
    saveAvatarImage,
    removeUser 
} from '@root/contexts/AuthContext';
import { useStore } from '@root/contexts/Store';
import { showMessage, showConfirmDialog } from '@root/actions/messageManager-actions';
import { ZipSelector, Captcha } from '@root/components';
import { ZipItem } from "@root/service/stoneService";
import { useNavigate } from 'react-router-dom';
// import { debounce } from '@root/helpers/shared';
import { useEffect } from "react";
import CS from '@root/i18n/cs';
import { debounce } from "lodash"

// icons
import { BsPencil } from 'react-icons/bs';
import { GiCancel } from 'react-icons/gi';


import Avatar from "./Avatar";

import { useMessageManager } from '@root/contexts/MessageManager';
import {
    ConfirmTemplates
} from '@root/templates/confirmTemplates';


const USER_LENGTH = 4;

export default function Profile() {
    const { currentUser, refreshUser, logout } = useAuth();
    const { dispatch } = useStore();

    const [originImage, setOriginImage] = useState();
    const [avatarData, setAvatarData] = useState();

	const [busy, setBusy] = useState(false);
	const [submiting, setSubmiting] = useState(false);
    const [error, setError] = useState('');
    const [userName, setUserName] = useState((currentUser.info?.user?.userName) || '');
    const [email, setEmail] = useState((currentUser.email) || '');
    const [existingUserName, setExistingUserName] = useState(false);
    const [changed, setChanged] = useState(false);
    const [zip, setZip] = useState<string>(currentUser.info?.user?.zip);
    const [isCaptchValid, setIsCaptchValid] = useState(false);
    const [editingProfile, setEditingProfile] = useState(!currentUser.info);

    const [confirmDialogId, setConfirmDialogId] = useState(0);
    const { stateMessages, dispatchMessages } = useMessageManager();
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const navigate = useNavigate();

    useEffect(() => window.scroll({ top: 0, behavior: 'smooth' }) , []);
    
    useEffect(() => {
        if (avatarData) {
            setChanged(true);
        }
    }, [avatarData]);


    // TODO: FIX
    useEffect(() => {
        const submitDisabled = !changed || (!zip ||  existingUserName || userName.length < 4);
        setSubmitDisabled(submitDisabled);
        // console.log(submitDisabled);
    }, [changed, zip, existingUserName, userName.length]);

    const debouncedUserExistenceCheck = useCallback(
        debounce(async (name: string) =>  {
            try {
                if (currentUser.info?.user?.userName !== name) {
                    const resp = await isExistingUser(name);
                    setExistingUserName(resp.exists && resp.exists !== undefined);
                }
            } catch(error: any) {
                dispatch(showMessage({
                    type: 'error',
                    message: error.message
                }));
            }
        }, 100)
    , []);

    async function handleUserNameChanged(e: any) {
        const userName = e.target.value;
        setUserName(userName!);
        if (userName.length >= USER_LENGTH) {
            setChanged(true);
            debouncedUserExistenceCheck(e.target.value);
        }
    }

    function handleEmailChanged(e: any) {
        const email = e.target.value;
        setEmail(email);
    }

    async function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        setSubmiting(true);
        if (!zip) {
            return;
        }

        setBusy(true);
        setError('');
        try {
            await createOrUpdateUser(userName, zip);
            await refreshUser();
            setBusy(false);
            // navigate('/');
            document.location.reload();
        } catch(e) {
            setBusy(false);
            setError(CS.pages.profile.messages.error.updateError);
        }
    }

    async function handleSaveAvatarImage() {
        setSubmiting(true);
        setBusy(true);
        setError('');
        try {
            await saveAvatarImage(originImage, avatarData);
            setBusy(false);
            return await refreshUser();
        } catch(e) {
            setBusy(false);
            setError(CS.pages.profile.messages.error.updateError);
        }
    }

    async function handleRemoveAccont() {
        const id = +new Date();
        setConfirmDialogId(id);
        dispatchMessages(showConfirmDialog(id, ConfirmTemplates.REMOVE_ACCOUNT));
    }
    async function handeleCancelEdit() {
        const action = !editingProfile;
        setEditingProfile(action);
        // setExistingUserName(false);
        if (!action) {
            document.location.reload();
        }
    }

    function handleExportData() {
        const user = currentUser.info?.user;
        const data = {
            userName: user.userName,
            email: currentUser.email,
            zip: user.zip,
        };
        const csvHeader = Object.keys(data).join(',');
        const csvRow = Object.values(data).map((value: any) => `"${value.replace(/"/g, '""')}"`).join(',');
        const csvContent = `data:text/csv;charset=utf-8,${csvHeader}\n${csvRow}`;
    
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "userData.csv");
        document.body.appendChild(link);
    
        link.click();
        document.body.removeChild(link);
    }


    // delete user
    useEffect(() => {
        (async () => {
            if (stateMessages.confirmDialog && stateMessages.confirmDialog.confirmed) {
                if (stateMessages.confirmDialog.id === confirmDialogId) {
                    setBusy(true);
                    try {
                        await removeUser();
                        await logout();
                        dispatchMessages(showMessage({
                            type: 'info',
                            message: CS.pages.profile.messages.info.userRemoved
                        }));

                        navigate('/');
                        // document.location.reload();
                    } catch(error: any) {
                        setBusy(false);
                        dispatchMessages(showMessage({
                            type: 'error',
                            message: error.message
                        }));
                    }
                }
            }
        })();
    }, [stateMessages])

    // const a = ((!avatarImage && !currentUser?.info?.user.photoURL) || (!avatarImage && currentUser?.info?.user.photoURL));
    // console.log(!avatarImage && !currentUser?.info?.user.photoURL);
    // console.log((!avatarImage && currentUser?.info?.user.photoURL));
    // console.log(((!avatarImage && currentUser?.info?.user.photoURL === undefined) || (!avatarImage && currentUser?.info?.user.photoURL !== undefined)));


    return (
        <div className="container" id="profile">
            <br />


            {busy &&
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }

            {!busy && 
                <>
                    {!currentUser.info &&
                        <div className="alert alert-success" role="success">
                            <h4 className="alert-heading">{CS.pages.profile.welcome.title}</h4>
                            <p>{CS.pages.profile.welcome.description}</p>
                        </div>
                    }

                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 avatar">
                            <Avatar 
                                setAvatarData={setAvatarData}
                                setOriginImage={setOriginImage}
                                saveAvatarImage={handleSaveAvatarImage}
                            />
                            <h3 className="userName">
                                {userName}
                            </h3>                            
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{CS.pages.profile.userInfo.title}</label>
                            <input readOnly={!editingProfile} disabled={!editingProfile} className="form-control" type="userName" value={userName} onChange={handleUserNameChanged} placeholder={CS.pages.profile.userInfo.placeHolder} />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{CS.pages.profile.userInfo.email}</label>
                            <input readOnly={!editingProfile} disabled={!editingProfile} className="form-control" type="userName" value={email} onChange={handleEmailChanged}  />
                            
                        </div>

                        {userName.length < USER_LENGTH &&
                            <div className="alert alert-warning" role="alert">
                                {CS.pages.profile.messages.warning.minNameLength}
                            </div>
                        }

                        {existingUserName &&
                            <div className="alert alert-danger" role="alert">
                                {CS.pages.profile.messages.warning.sameUser}
                            </div>
                        }

                        {!editingProfile &&
                            <div className="mb-3">
                                <label className="form-label">{CS.pages.profile.zip}</label>
                                <input readOnly={true} disabled={true} className="form-control" type="userName" value={currentUser.info?.user?.zip} />
                            </div>
                        }

                        <div className="mb-3">
                            {editingProfile &&
                                <div className="alert alert-primary" role="alert">
                                    {CS.pages.profile.yourZip}                                
                                </div>
                            }

                            {editingProfile &&
                                <ZipSelector
                                    defaultZip={currentUser.info?.user?.zip}
                                    singlePick={true}
                                    formatSelected={(selected: ZipItem) => `${selected.zip} - ${selected.district}`}
                                    changed={(items: ZipItem[]) => {
                                        setZip(items[0].zip);
                                        
                                        if (currentUser.info?.user?.zip !== items[0].zip) {
                                            setChanged(true);
                                        }
                                    }}
                                />
                            }
                        </div>

                        {/* {changed ? 'changed': ''} */}

                        {error &&
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        }
                        <br />

                        {submiting && !zip && 
                            <div className="alert alert-warning" role="alert">
                                {CS.pages.profile.messages.warning.zipMandatory}
                            </div>
                        }

                        {currentUser.info &&
                            <>
                                <button type="button" className='btn btn-primary' onClick={handeleCancelEdit}>
                                    {!editingProfile && <><BsPencil></BsPencil>&nbsp;{CS.pages.profile.editProfile.edit}</>}
                                    {editingProfile && <><GiCancel />&nbsp;{CS.pages.profile.editProfile.cancelEdit}</>}
                                </button>&nbsp;
                            </>
                        }
                        
                        {editingProfile &&
                            <button type="submit" className='btn btn-primary' disabled={submitDisabled}>
                                {CS.labels.saveChanges}
                            </button>
                        }

                        <br /><br />
                        <hr />

                        <div className="alert alert-primary" role="alert">
                            <h3>Právo na přenositelnost údajů</h3>
                                V souladu s Obecným nařízením o ochraně osobních údajů (GDPR) máte právo získat osobní údaje, které jste nám poskytli, v strukturovaném, běžně používaném a strojově čitelném formátu. Máte také právo tyto údaje přenést jinému správci údajů.
                                Pro stažení vašich údajů klikněte na tlačítko níže. Data budou dostupná ve formátu, který lze snadno přenést.
                            
                            <br />
                            <br />
                            <button type="button" className='btn btn-primary' onClick={handleExportData}>
                                {CS.pages.profile.export.title}
                            </button>
                        </div>

                        <br /><br />

                        <div className="collapse alert alert-danger" id="collapseExample">
                            <div className="card card-body">
                                <br />
                                <p><b>Důležité upozornění: </b> Máte možnost odstranění uživatelského profilu.</p>
                                <p>Vaše spokojenost je pro nás prioritou, a proto jsme připravili několik užitečných informací o procesu odstranění vašeho uživatelského účtu. Chceme vás však varovat, že tato akce je nevratná a s sebou nese důsledky. Pečlivě si promyslete následující informace před tím, než se rozhodnete pokračovat.</p>
                                <p>Odstraněním vašeho uživatelského účtu ztratíte následující výhody a možnosti:</p>
                                
                                <ul>
                                    <li><b>Přístup ke kamínkovníku:</b> Již nebudete moci využívat veškeré funkce a možnosti naší aplikace, včetně sdílení svých zážitků a objevů kamínků s ostatními uživateli.</li>
                                    <li><b>Sběr informací:</b> Vaše dosavadní data, včetně uložených kamínků a sdílených příběhů, budou nenávratně smazána. Nebudete mít možnost je obnovit.</li>
                                    <li><b>Komunitní interakce:</b> Přijdete o možnost navazovat nová přátelství, sdílet inspiraci a komunikovat s ostatními vášnivými objeviteli kamínků.</li>
                                </ul>
                                <p>Prosíme, věnujte tomuto rozhodnutí dostatečnou pozornost a zvážte důsledky této akce. Pokud stále trváte na odstranění svého uživatelského účtu, vyřešte následující matematickou úlohu a klikněte na tlačítko "Odstranit".</p>

                                <Captcha captchValid={setIsCaptchValid}/>
                                <br />

                                <button type="button" onClick={handleRemoveAccont} className='btn btn-danger' disabled={!isCaptchValid}>
                                    {CS.labels.remove}
                                </button>
                            </div>
                        </div>
   

                        <div className="accordion" id="removeUser">
                            <div className="accordion-item alert alert-danger">
                                <h3 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <h3 className="alert-heading" id="headingTwo">
                                            Odstranění uživatelského účtu
                                        </h3>
                                    </button>
                                </h3>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#removeUser">
                                        <br />
                                        <p><b>Důležité upozornění: </b> Máte možnost odstranění uživatelského profilu.</p>
                                        <p>Vaše spokojenost je pro nás prioritou, a proto jsme připravili několik užitečných informací o procesu odstranění vašeho uživatelského účtu. Chceme vás však varovat, že tato akce je nevratná a s sebou nese důsledky. Pečlivě si promyslete následující informace před tím, než se rozhodnete pokračovat.</p>
                                        <p>Odstraněním vašeho uživatelského účtu ztratíte následující výhody a možnosti:</p>
                                        
                                        <ul>
                                            <li><b>Přístup ke kamínkovníku:</b> Již nebudete moci využívat veškeré funkce a možnosti naší aplikace, včetně sdílení svých zážitků a objevů kamínků s ostatními uživateli.</li>
                                            <li><b>Sběr informací:</b> Vaše dosavadní data, včetně uložených kamínků a sdílených příběhů, budou nenávratně smazána. Nebudete mít možnost je obnovit.</li>
                                            <li><b>Komunitní interakce:</b> Přijdete o možnost navazovat nová přátelství, sdílet inspiraci a komunikovat s ostatními vášnivými objeviteli kamínků.</li>
                                        </ul>
                                        <p>Prosíme, věnujte tomuto rozhodnutí dostatečnou pozornost a zvážte důsledky této akce. Pokud stále trváte na odstranění svého uživatelského účtu, vyřešte následující matematickou úlohu a klikněte na tlačítko "Odstranit".</p>

                                        <Captcha captchValid={setIsCaptchValid}/>
                                        <br />

                                        <button type="button" onClick={handleRemoveAccont} className='btn btn-danger' disabled={!isCaptchValid}>
                                            {CS.labels.remove}
                                        </button>
                                </div>
                            </div>
                        </div>
                                 

                        <br /><br />
                        <br /><br />
                        <br /><br />
                    </form>
                </>
            }
        </div>
    );
}


import { 
    ZipItem, 
    StoneItem 
} from "@root/service/stoneService";


// export function mapStoneItem(doc: QueryDocumentSnapshot<any>, data: Stone) {
//     // const date = data.timestamp.toDate();
//     // const editDate = data.editTimestamp ? data.editTimestamp.toDate() : null;
//     // const resp = {
//     //     id: doc.id,
//     //     zipList: data.zipList,
//     //     zipRawList: data.zipRawList,
//     //     description: data.description,
//     //     stoneCount: data.stoneCount,
//     //     user: data.user,
//     //     likes: data.likes,
//     //     timestamp: data.timestamp,
//     //     editTimestamp: data.editTimestamp,
//     //     date: moment(date).fromNow(),
//     //     editDate: editDate ? moment(editDate).fromNow() : null,
//     //     stones: new StoneList(data.stones),
//     //     rejectionReason: data.rejectionReason,
//     //     state: data.state,
//     //     position: data.position,
//     //     comments: data.comments || []
//     // };

//     // return resp;
// }


export async function mapComments() {
    throw "not implemented";
    // comments.forEach(comment => {
    //     if (comment.uid) {
    //         promiseList.push(getUserInfo(null, comment.uid).then((user: any) => {
    //             comment.metadata = {
    //                 userName: user.name,
    //                 photoURL: user.photoURL || null
    //             }
    //             return comment;
    //         }));
    //     } else {
    //         promiseList.push(Promise.resolve(comment));
    //     }
    // });

    // return Promise.all(promiseList);
}


export function getPhotos(data: any): any {
    const item = data.map((item: StoneItem) => {
        return {
            stoneId: item.stoneId,
            src: item.url,
            width: item.info.width,
            height: item.info.height,
            caption: item.zipList.map((item: ZipItem) => `${item.zip} - ${item.district}`)
        };
    });
    return item;
}
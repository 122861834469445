import 'moment/locale/cs';

import { getJwtToken } from '../contexts/AuthContext';

import { MAP } from '@root/config/endPoints';

export async function getCity(lat: number, lng: number) {
    const { token } = await getJwtToken();

    const options: any = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        }
    };
    const url = `${MAP.getCity}?lat=${lat}&lng=${lng}`;

    return fetch(url, options)
        .then(resp => {
            if (!resp.ok) {
                throw resp;
            }
            return resp.json();
        })
        .then((resp: any) => {
            return resp;
        })
        .catch(err => {
            return err.text().then((message: string) => {
                console.error('cannot get city coordinate', err);
                throw JSON.parse(message);
            })
        });
}

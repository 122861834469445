import "./PasswordToggle.scss";
import { useState, Dispatch, SetStateAction, useEffect } from 'react';

import CS from '@root/i18n/cs';
interface CaptchaProps {
    captchValid: Dispatch<SetStateAction<boolean>>,
}

export default function Captcha({ ...rest }: CaptchaProps) {
    const { captchValid } = rest;
    const [captcha] = useState({
        num1: parseInt("" + Math.random() * 10) + parseInt("" + Math.random() * 10),
        num2: parseInt("" + Math.random() * 10) + parseInt("" + Math.random() * 10)
    })
    const [captchaResult, setCaptchaResult] = useState<string>("");

    useEffect(() => {
        captchValid(Number(captchaResult) === captcha.num1 + captcha.num2);
    }, [captchaResult])

    return (
        <>
            <label className="form-label">{CS.components.captcha.enterResponse} {captcha.num1} + {captcha.num2}</label>
            <input name="captcha" className="form-control" value={captchaResult} onChange={e => setCaptchaResult(e.target.value)} type="number" placeholder={CS.components.captcha.validate} />
        </>
    )
}
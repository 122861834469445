import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Outlet } from 'react-router-dom';

export default function PrivateRouter() {
	// const { token } = getJwtToken();
    const { currentUser } = useAuth();

    if (currentUser && currentUser.info) {
        return <Outlet />;
    } if (currentUser && !currentUser.info) {
        return <Navigate to="/profile" />
    } else {
        return <Navigate to="/about" />
    }
}
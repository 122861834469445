import "./articles.scss"
// import React from 'react';

// import lenka from './images/lenka.png'
// console.log(lenka)

export default function Articles() {

    return (
        <div className="container articles" id="articles">
            <br />
            <br />
            <div className="row articles-article">
                <h1>Úvod do světa malovaných kamínků</h1>
                <hr />
                <br />

                <div className="col-lg-3 articles-article-image">
                    <img src="images/content/child-painting.jpg" className="card-img-top" alt="lenka" />
                </div>
                <div className="col-lg-9 articles-article-content">
                    <p>
                        Malované kamínky jsou populární formou umění a zábavy, která spojuje kreativitu s přírodou. Tento koníček získal v posledních letech na popularitě a stává se způsobem, jak se lidé mohou vyjádřit, relaxovat a sdílet radost s ostatními. Včetně dětí, které nacházejí radost jak v hledání, tak v malování těchto barevných kamínků.
                        <br />
                        <br />
                        Pro mnohé se malování kamínků stává meditativní praxí, která umožňuje únik od běžných stresů a povinností. Soustředění se na každý tah štětce nebo výběr barvy poskytuje prostor pro klid a uvolnění mysli, zatímco vizuální a fyzický kontakt s kamínkem může působit uklidňujícím dojmem. Tato aktivita také stimuluje fantazii, protože umělci, ať už mladí či staří, mohou přetvářet obyčejné kameny na živá umělecká díla.
                        <br />
                        <br />
                        Kromě osobního potěšení a relaxace malování kamínků podporuje i sociální interakci a komunitní ducha. Lidé často sdílejí své kamínky veřejně jako formu umění "zapoj a pusť", což přináší nečekané momenty radosti náhodným nálezcům. Tato praxe nejenže rozjasňuje dny cizích lidí, ale také vytváří pocit propojenosti a společenství mezi účastníky.
                        <br />
                        <br />
                        Ve výsledku malování kamínků poskytuje jednoduchou, ale hluboce obohacující činnost, která spojuje lidi různých věkových skupin a profesí přes společnou lásku k umění a přírodě.

                    </p>

                </div>
            </div>

            <br />
            <br />
            <div className="row articles-article">
                <h1>Proč začít s malováním kamínků</h1>
                <hr />
                <div className="col-lg-3 articles-article-image">
                    <img src="images/content/child-painting-with-mather.jpg" className="card-img-top" alt="lenka" />
                </div>
                <div className="col-lg-9 articles-article-content">
                    <ul>
                        <li>
                            <b>Kreativita:</b> Malování vám umožní projevit svou kreativitu a experimentovat s různými barvami a technikami, poskytuje prostor pro vyjádření osobních emocí a myšlenek prostřednictvím vizuálních obrazů.
                            <br /><br />
                        </li>
                        <li>
                            <b>Relaxace:</b> Tento způsob uměleckého vyjádření nabízí prostor pro klid a soustředění. Když se vaše pozornost plně soustředí na proces tvorby, vaše mysl se přirozeně uvolňuje a dopřává vám pocit pokoje a spokojenosti. Tímto způsobem malování na kamínky přispívá k lepší duševní pohodě a může dokonce podporovat větší odolnost vůči stresu v každodenním životě.
                            <br /><br />
                        </li>
                        <li>
                            <b>Darování radosti:</b> Malované kamínky často slouží jako drobné dárky či nečekaná překvapení pro náhodné nálezce, vnášející do každodenního života malý kousek radosti a štěstí. Pro děti je obzvláště vzrušující najít hezký kamínek nebo vytvořit vlastní dílo, které mohou zanechat jako překvapení pro dalšího nálezce. Tímto způsobem se rozvíjí jejich tvořivost a učí se hodnotě sdílení a radosti z dávání. Tato jedinečná forma uměleckého vyjádření a interakce nabízí jednoduchý, ale mocný způsob, jak rozšířit radost a pozitivitu ve světě. Děti se také mohou naučit o emocionálním významu darování a radosti z toho, že jejich malované kamínky mohou vykouzlit úsměv na tváři někoho jiného.
                        </li>
                    </ul>
                </div>
            </div>

            <br />
            <br />
            {/* <div className="row articles-article">
                <h1>Techniky malování</h1>
                <hr />

                <div className="col-lg-3 articles-article-image">
                    <img src="images/content/techniques2.jpg" className="card-img-top" alt="lenka" />
                </div>

                <div className="col-lg-9 articles-article-content">
                    <ul>
                        
                        <li>
                            <b>Pointilismus:</b>&nbsp;
                            Pointilismus je technika, kde jsou obrázky vytvářeny pomocí malých, jasně oddělených bodů různě barevných barev, které se spojí do celkového obrazu.
                            <br />
                            <b>Jak na to:</b> Pro tento styl budete potřebovat tenký štětec nebo konec špejle pro vytváření malých bodů. Pracujte trpělivě a přidávejte body vedle sebe, dokud nevytvoříte požadovaný obrazec nebo design.
                        </li>
                        <li>
                            <b>Svobodné malování rukou:</b>&nbsp;
                            Pro ty, kteří mají více zkušeností nebo chtějí experimentovat, je svobodné malování rukou ideální. Tato technika vám umožní projevit vaši kreativitu bez jakýchkoli omezení.
                            <b>Jak na to:</b> Použijte štětce různých velikostí pro dosažení různých úrovní detailu. Malování rukou vyžaduje pevnou ruku a někdy i několik vrstev barvy pro zvýraznění detailů.
                        </li>
                        <li>
                            <b>Technika mokré na mokré:</b>&nbsp;
                            Technika mokré na mokré umožňuje barvám přirozeně se mísit přímo na kamenu, což vytváří krásné gradienty a barevné přechody.
                            <b>Jak na to: </b> Naneste základní vrstvu barvy a než začne schnout, přidejte další barvu, kterou chcete smíchat. Použijte štětec k roztírání a míchání barev přímo na kameni.
                        </li>
                        <li>
                            <b>Dotting nástroje:</b>&nbsp;
                            Dotting nástroje jsou perfektní pro vytváření přesných a jednotných bodů, které jsou ideální pro detailní práci a jemné vzory.
                            <b>Jak na to:</b> Sada dotting nástrojů obvykle obsahuje různé velikosti konců, které se mohou používat pro vytváření různých velikostí bodů. Ponořte konec nástroje do barvy a aplikujte na kámen s rovnoměrným tlakem.
                        </li>
                    </ul>
                </div>
                
            </div> */}

        </div >
    );
};

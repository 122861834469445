import "./approveStones.scss"
import { useEffect, useState } from 'react';
// import Carousel, { Modal, ModalGateway } from "react-images";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from 'react-router-dom';
import CS from '@root/i18n/cs';

import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from '../../contexts/AuthContext';
import { useStore } from '../../contexts/Store';
import StoneContent from '@root/components/StoneContent';

import { getStones, rejectStone, Stone } from "@root/service/stoneService";
import { searchStone, SearchOptions } from '../../actions/actions';

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdOutlineCancel } from 'react-icons/md';

import { approveStone } from '../Stones/stoneHelper';
import { StoneState } from '@root/helpers/constants';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { sendMessage } from '@root/helpers/errorHandling';

export default function ApproveStones() {
    const [busy, setBusy] = useState(true);
    const [stones, setStones] = useState<any[]>([]);
    const [lastKey, setLastKey] = useState<string>();


    const [searchParams, setSearchParams] = useState<SearchOptions>();

    useEffect(() => window.scroll({ top: 0, behavior: 'smooth' }) , []);
    
    const { currentUser, refreshUser } = useAuth();
    const { state, dispatch } = useStore();
    const location = useLocation();

    const showMyStones = location.search.toLocaleLowerCase().indexOf('?my') >= 0;
    const [userName] = useState(showMyStones ? currentUser.uid : '');


    async function filterStones(search: SearchOptions = {}, lastKey: any = undefined) {
        setBusy(true);
        search = { ...search, state: StoneState.PENDING };

        return getStones({ search, key: lastKey }).then(resp => {
            setLastKey(resp.lastKey)

            let array = [...stones, ...resp.stones.filter((stone: Stone) => {
                return stones.filter((s: Stone) => s.trackingId === stone.trackingId).length === 0;
            })].sort((a: Stone, b: Stone) => {
                return b.timestamp - a.timestamp;
            })
            setStones(array);

            setBusy(false);
        });
    }

    useEffect(() => {
        if (showMyStones) {
            const search = { userName };
            setSearchParams(search);
            filterStones(search);
        } else {
            filterStones(state.search);
        }
        // filterStones(state.search);
    }, [state]);
    
    // useEffect(() => {
    //     if (showMyStones) {
    //         const search = { userName };
    //         setSearchParams(search);
    //         filterStones(search);
    //     } else {
    //         filterStones(state.search);
    //     }
    // }, []);

    // const openLightbox = useCallback((index: number, group: number) => {
    //     setActiveGroup(group);
    //     setCurrentImage(index);
    //     setViewerIsOpen(true);
    // }, []);

    // const closeLightbox = () => {
    //     setCurrentImage(0);
    //     setViewerIsOpen(false);
    // };

    // function getPhotos(data: any) {
    //     const item = data.map((item: StoneItem) => {
    //         return {
    //             src: item.url,
    //             // thumbnail: item.url,
    //             // thumbnailWidth: item.info.width,
    //             // thumbnailHeight: item.info.height,
    //             // tags:item.zipList.map((zip: ZipItem) => {
    //             // 	return { value: zip.zip + ' ' + zip.name, title: zip.zip}// - ${zip.name} ${zip.district}`
    //             // }),
    //             width: item.info.width,
    //             height: item.info.height,
    //             caption: item.zipList.map((zip: ZipItem) => {
    //                 return `${zip.zip} - ${zip.name} ${zip.district}`
    //             }).join(', ')
    //         };
    //     });

    //     return item;

    //     // return [
    //     // 	{
    //     // 		src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    //     // 		width: 1,
    //     // 		height: 1
    //     // 	  }
    //     // ];
    // }


    async function deleteStoneHandler(item: Stone, reason: string) {
        setStones([]);
        setBusy(true);

        try {
            await rejectStone(item, reason);
            await refreshUser();
            dispatch(searchStone({}));
            setBusy(false);
            setRejectionReason('');
            // document.location.reload();
        } catch(e) {
            sendMessage(currentUser.displayName, 'Cannot reject stone');
            setBusy(false);
        }
    }

    async function approveStoneHandler(item: Stone) {
        setStones([]);
        setBusy(true);
        try {
            await approveStone(item);
            await refreshUser();
            dispatch(searchStone({}));
            setRejectionReason('');
            document.location.reload();
        } catch(e) {
            sendMessage(currentUser.displayName, 'Cannot approve stone');
            setBusy(false);
        }
    }

    // async function updateStoneCountHandler(e: any) {
    //     alert('neni hotove')
    //     e.preventDefault();
    // }

    const [showRejectionReason, setShowRejectionReason] = useState<Stone | undefined>();
    const [rejectionReason, setRejectionReason] = useState('');

    return (
        <div className="container" id="approveStones">
            <br />

            <Modal show={showRejectionReason !== undefined} onHide={() => setShowRejectionReason(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{CS.pages.approveStone.rejectDialog.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {CS.pages.approveStone.rejectDialog.description}
                    <textarea 
                        value={rejectionReason}
                        onChange={e => setRejectionReason(e.target.value!)}
                        className="form-control" id="rejectionReason" rows={3}>
                    </textarea>    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRejectionReason(undefined)}>
                        {CS.labels.cancel}
                    </Button>
                    <Button variant="primary" disabled={!rejectionReason.length} onClick={async () => {
                        if (showRejectionReason) {
                            await deleteStoneHandler(showRejectionReason, rejectionReason);
                        }
                        setShowRejectionReason(undefined);
                    }}>
                        {CS.labels.reject}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="alert alert-info" role="alert">
                <h4 className="alert-heading">{CS.pages.approveStone.header}</h4>
                <p>{CS.pages.approveStone.description}</p>
                <hr />
                <div className="mb-0">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                {CS.pages.approveStone.totalCountToApprove}
                            </div>
                            <span className="badge bg-primary rounded-pill">{stones.length}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <InfiniteScroll
                dataLength={stones.length}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                next={() => {
                    filterStones(searchParams, lastKey);
                }}
                hasMore={false}
                loader={null}
            >
                <div className="row justify-content-md-center">
                    {stones.map((item: Stone, index: any) => (
                        <StoneContent 
                            index={index} 
                            key={index}
                            item={item}
                            stones={stones}
                            setStones={setStones}
                            // setDefaultZip={setDefaultZip}
                            disableActions={true}
                            setBusy={setBusy}
                            customActions={() => {
                                return (
                                    <>
                                        <div className={"col action-buttons-item"}
                                            onClick={() => approveStoneHandler(item)} >
                                            <AiOutlineCheckCircle size={28} /><span>{CS.labels.approve}</span>
                                        </div>

                                        <div className="col action-buttons-item delete" onClick={() => setShowRejectionReason(item) }>
                                            <MdOutlineCancel size={28} color="red" />
                                            <span>{CS.labels.reject}</span>
                                        </div>
                                    </>
                                );
                            }}
                        />
                            
                        
                    ))}
                </div>
            </InfiniteScroll>
            {busy &&
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }

            {!busy && stones && !stones.length &&
                <div className="alert alert-primary" role="alert">
                    {CS.messages.warning.emptyApproveList}
                </div>
            }

            <br />
            <br />
        </div>
    );
};

import * as types from '@root/actions/action-types';
import { TrackingTemplate } from '@root/actions/messageManager-actions';


export interface ConfirmDialog {
    visible: boolean;
    title: string;
    trackingTemplate: TrackingTemplate;
}

export interface State {
    busy: boolean;
    message: any;
    confirmDialog: ConfirmDialog;
}


export const initialState: State = {
    busy: false,
    message: undefined,
    confirmDialog: {} as ConfirmDialog
};

export const reducer = (state = initialState, action: any) => {
    console.log(state);
    if (action.type === types.SET_BUSY) {
        return {
            ...state,
            busy: action.value,
        };
    } 
    if (action.type === types.SHOW_MESSAGE) {
        return {
            ...state,
            message: action.value,
        };
    } 
    if (action.type === types.HIDE_MESSAGE) {
        return {
            ...state,
            message: null,
        };
    } 
    if (action.type === types.SHOW_CONFIRM_DIALOG) {
        return {
            ...state,
            confirmDialog: { ...action, visible: true },
        };
    }    
    if (action.type === types.HIDE_CONFIRM_DIALOG) {
        return {
            ...state,
            confirmDialog: { 
                ...state.confirmDialog, visible: false, confirmed: action.value
            },
        };
    }    

    // throw "unknown action";
};
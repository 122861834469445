import "./mapView.scss"
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'


// interface LocationMarkerProps {
//     position: MapPosition;
//     setPosition: Dispatch<SetStateAction<MapPosition>>;
// }

// function LocationMarker({ ...rest }: LocationMarkerProps) {
//     const { position, setPosition } = rest;

//     const markerRef = useRef<any>(null);
//     const eventHandlers = useMemo(
//         () => ({
//             dragend() {
//                 const marker = markerRef.current
//                 if (marker != null) {
//                     setPosition(marker.getLatLng())
//                 }
//             },
//         }),
//         [],
//     )
//     useMapEvents({
//         click(e) {
//             setPosition(e.latlng);
//         }
//     })

//     return !position.lat ? null : (
//         <Marker ref={markerRef} position={position} draggable={true} eventHandlers={eventHandlers}>
//             <Popup>You are here</Popup>
//         </Marker>
//     )
// }

export default function MapView() {
    const position: any = [51.505, -0.09];

    return (
        <div className="container map-view" >
            <br />

            <MapContainer
                center={{ lat: 49.844, lng: 18.19 }}
                zoom={13}                                
                scrollWheelZoom={true}
                style={{ height: '400px', width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                        <Marker position={position}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker>
            </MapContainer>
        </div>
    );
};

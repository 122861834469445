import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { REGISTER_ERROR_CODES, useAuth } from '../../contexts/AuthContext';
import { useNavigate, NavLink } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
import { isTestEnv, isLocalEnv } from '@root/helpers/shared';

import CS from '@root/i18n/cs';
import "./LandingPage.scss";

import { PasswordToggle, PasswordRules, Captcha } from '@root/components';
import { LoginMode } from './LandingPage';

import { Modal, Button } from 'react-bootstrap';

interface LoginRegisterProps {
    loginMode: LoginMode;
    error: any;
    setError: Dispatch<SetStateAction<string>>,
    changeMode: (mode: LoginMode) => void;
}

export default function LoginRegister({ ...rest }: LoginRegisterProps) {
    const {
        error,
        setError,
        loginMode,
        changeMode
    } = rest;
    const { login, register } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [registerUserName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [passwordValidate, setPasswordValidate] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [passwordisValid, setPasswordisValid] = useState(false);
    const [isCaptchValid, setIsCaptchValid] = useState(false);

    const [busy, setBusy] = useState(false);
    const navigate = useNavigate();
    const [readOnly, setReadOnly] = useState(true);
    const [privacy, setPrivacy] = useState(false);
    const [gdpr, setGdpr] = useState(false);

    const [showUrlContent, setShowUrlContent] = useState<string | undefined>(undefined);
    // const handleClose = () => setShow(false);
    const handleLinkClick = (e: any, url: string) => {
        setShowUrlContent(url);
        e.preventDefault()
        return false;
    }


    useEffect(() => {
        if (loginMode === LoginMode.REGISTER) {
            setTimeout(() => setReadOnly(false), 1000);
        } else {
            setReadOnly(true);
        }
    }, [loginMode]);


    async function handleLoginSubmit(e: any) {
        e.preventDefault();

        try {
            delete sessionStorage.loginMessage;
            setError('');
            setBusy(true);
            await login(email.trim(), password.trim());
            navigate('/stones')
        } catch (err: any) {
            if (err.errorCode) {
                const code: number = Number(err.errorCode);
                sessionStorage.loginMessage = (CS.errorCodes as any)[code];
            } else if (err.code === REGISTER_ERROR_CODES.NotAuthorizedException) {
                sessionStorage.loginMessage = CS.pages.login.messages.error.notAuthorizedException;
            } else if (err.code === REGISTER_ERROR_CODES.UserNotConfirmedException) {
                sessionStorage.loginMessage = CS.pages.login.messages.error.notApprowedUser;
            } else if (err.code === REGISTER_ERROR_CODES.UserNotFoundException) {
                sessionStorage.loginMessage = CS.pages.login.messages.error.userNotFoundException;
            } else {
                sessionStorage.loginMessage = err.toString(); //CS.pages.login.messages.error.invalidLogin;
            }
            setError(sessionStorage.loginMessage);
            setBusy(false);
            console.error(err);
        }

        return false;
    }

    async function handleRegisterSubmit(e: any) {
        e.preventDefault();

        try {
            delete sessionStorage.loginMessage;
            setBusy(true);
            setError('');
            await register(registerUserName.trim(), registerEmail.trim(), registerPassword.trim());
            changeMode(LoginMode.FIRST_LOGIN);

        } catch (err: any) {
            if (err.code === REGISTER_ERROR_CODES.UserNotConfirmedException) {
                sessionStorage.loginMessage = CS.pages.login.messages.error.notApprowedUser;
            } else if (err.code === REGISTER_ERROR_CODES.UsernameExistsException) {
                sessionStorage.loginMessage = CS.pages.login.messages.error.alreadyRegister;
            } else {
                // sessionStorage.loginMessage = 'Špatně zadané jméno nebo heslo.';
                sessionStorage.loginMessage = err.toString(); //CS.pages.login.messages.error.invalidLogin;
            }
            setBusy(false);
            setError(sessionStorage.loginMessage);
            console.error(err);
        }

        return false;
    }

    // function handleFocus(e: React.SyntheticEvent) {
    //     const top = (e.target as any).offsetTop;// (e.target as any).getBoundingClientRect().top - 80;
    //     // debugger;
    //     // document.body.scrollTop += top;
    //     // window.scrollY + 
    //     window.scroll({ top: top, behavior: 'smooth' });
    //     console.log(top, document.body.scrollTop);
    // }


    return (
        <div className="loginRegister-panel">
            {(loginMode === LoginMode.LOGIN || loginMode === LoginMode.FIRST_LOGIN) &&
                <form onSubmit={handleLoginSubmit}>
                    {loginMode === LoginMode.FIRST_LOGIN &&
                        <>
                            <div className="alert alert-primary" role="alert">
                                <p>{CS.pages.register.messages.info.part1}</p>
                                <p>{CS.pages.register.messages.info.part2}
                                    <a href={"mailto:" + CS.pages.register.messages.info.email}>{CS.pages.register.messages.info.email}</a>.</p>
                            </div>

                            <div className="loginRegister-panel-header">
                                {CS.labels.firstLogin}
                            </div>
                        </>
                    }

                    {loginMode === LoginMode.LOGIN &&
                        <div className="loginRegister-panel-header">
                            {CS.labels.login}
                        </div>
                    }

                    <div className="loginRegister-panel-body">
                        <div className="col-md-12">
                            <label className="form-label">{CS.pages.login.userName}</label>
                            <input autoCapitalize='none' className="form-control" type="email" value={email} onChange={e => setEmail(e.target.value!)} />
                        </div>
                        <div className="col-md-12 password">
                            <label className="form-label">{CS.labels.password}</label>
                            <PasswordToggle password={password} setPassword={setPassword} />

                        </div>

                        <div className="col-md-12">
                            <br />
                            <div className="d-grid gap-2">
                                <button disabled={!busy && (!email || !password)} type="submit" className="btn btn-primary">
                                    {CS.pages.login.submit}
                                </button>
                            </div>

                        </div>

                        {error &&
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        }

                        <hr />

                        <div className="col-md-12">
                            <div className="loginRegister-panel-body-other">
                                {/* {(!isTestEnv() || isLocalEnv()) &&
                                } */}
                                <NavLink onClick={() => changeMode(LoginMode.REGISTER)} to="#">{CS.labels.register}</NavLink>
                                <NavLink onClick={() => changeMode(LoginMode.FORGOT_PASSWORD)} to="#">{CS.labels.forgotPassword}</NavLink>
                            </div>
                        </div>
                    </div>
                </form>
            }
            {(!isTestEnv() || isLocalEnv()) && loginMode === LoginMode.REGISTER &&
                <form onSubmit={handleRegisterSubmit}>

                    <Modal show={showUrlContent !== undefined} onHide={() => setShowUrlContent(undefined) } size="xl">
                        <Modal.Body>
                            <iframe
                                src={showUrlContent + ".html?" + +new Date()}
                                width="100%"
                                height="500px"
                                style={{ border: "none" }}
                            ></iframe>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowUrlContent(undefined) }>
                                {CS.labels.close}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="loginRegister-panel-header">
                        {CS.pages.register.title}
                    </div>

                    <div className="loginRegister-panel-body">
                        {/* <div className="mb-md-12">
                            <label className="form-label">{CS.pages.register.userName}</label>
                            <input name="registerUserName" 
                                readOnly={readOnly} 
                                className="form-control" 
                                minLength={5}
                                autoCapitalize="none" autoComplete="off" type="text" value={registerUserName} onChange={e => setRegisterUserName(e.target.value!)} />
                        </div> */}
                        <div className="mb-md-12">
                            <label className="form-label">{CS.pages.register.email}</label>
                            <input name="registerEmail" readOnly={readOnly} className="form-control" autoCapitalize="none" autoComplete="off" type="email" value={registerEmail} onChange={e => setRegisterEmail(e.target.value!)} />
                        </div>
                        <div className="col-md-12 password">
                            <label className="form-label">{CS.pages.register.password}</label>
                            <PasswordToggle readOnly={readOnly} password={registerPassword} setPassword={setRegisterPassword} />
                        </div>

                        <div className="mb-md-12">
                            <label className="form-label">{CS.pages.register.checkPassword}</label>
                            <PasswordToggle readOnly={readOnly} password={passwordValidate} setPassword={setPasswordValidate} />
                        </div>

                        <div className="mb-3">
                            <Captcha captchValid={setIsCaptchValid} />
                        </div>


                        <PasswordRules
                            password={registerPassword}
                            passwordValidate={passwordValidate}
                            setPasswordisValid={setPasswordisValid}
                            passwordMatch={passwordMatch}
                            setPasswordMatch={setPasswordMatch}
                        />
                        <br />

                        <div>
                            <input checked={privacy} onChange={() => setPrivacy(!privacy)} className="form-check-input" type="checkbox" name="approwe" />&nbsp;
                            {CS.pages.register.terms.agreeWith} <NavLink onClick={(e: any) => handleLinkClick(e, '/privacy') } to="/privacy">{CS.pages.register.terms.part1}</NavLink>
                        </div>

                        <p>
                            <input checked={gdpr} onChange={() => setGdpr(!gdpr)} className="form-check-input" type="checkbox" name="approwe" />&nbsp;
                            {CS.pages.register.gdpr.agreeWith} <NavLink onClick={(e: any) => handleLinkClick(e, '/gdpr') } to="/gdpr">{CS.pages.register.gdpr.part1}</NavLink> {CS.pages.register.gdpr.part2}
                            {CS.pages.register.gdpr.part3}<NavLink onClick={(e: any) => handleLinkClick(e, '/gdpr')} to="/gdpr">{CS.pages.register.gdpr.part4}</NavLink>.
                            {/* Tímto uděluji webu kaminkovnik.cz nevýhradní, bezplatnou licenci k použití, reprodukci, zobrazování a distribuci fotografií, které nahrávám v rámci vytváření příspěvků na této platformě. Tato licence umožňuje webu kaminkovnik.cz zobrazovat mé fotografie ostatním uživatelům a využívat je pro účely provozu, propagace a zlepšení služeb platformy. Rozumím, že si ponechávám vlastnictví autorských práv k těmto fotografiím a mohu je kdykoliv odstranit z platformy, což ukončí nové udělení této licence pro dané fotografie, avšak fotografie již zobrazené uživatelům nebo použité v rámci propagace před odstraněním mohou zůstat v užití podle této licence. */}
                        </p>

                        <div className="col-md-12">
                            <br />
                            <div className="d-grid gap-2">
                                <button disabled={!busy && (!privacy || !gdpr || !registerEmail || !passwordisValid || !passwordMatch || !isCaptchValid)} type="submit" className="btn btn-primary">
                                    {CS.pages.register.submit}
                                </button>
                            </div>

                        </div>

                        {error &&
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        }

                        <br />

                        <hr />

                        <div className="col-md-12">
                            <div className="loginRegister-panel-body-other">
                                <NavLink onClick={() => changeMode(LoginMode.LOGIN)} to="#">{CS.labels.login}</NavLink>
                                <NavLink onClick={() => changeMode(LoginMode.FORGOT_PASSWORD)} to="#">{CS.labels.forgotPassword}</NavLink>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    );
}


import "./profile.scss"
import { useState, useRef, useEffect } from "react";

import { useAuth } from '@root/contexts/AuthContext';
import CS from '@root/i18n/cs';

// import Dropzone from "react-dropzone-kh";
import { FiSave } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';

import 'react-image-crop/src/ReactCrop.scss'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'

import { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180

export async function canvasPreview(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 0,
    rotate = 0,
) {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}


// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: 'px',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


export function useDebounceEffect(
    fn: () => void,
    waitTime: number,
    deps?: any,
) {
    useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, deps)
        }, waitTime)

        return () => {
            clearTimeout(t)
        }
    }, deps)
}


export default function Profile({ ...rest}: any) {
    const { 
        setOriginImage, 
        setAvatarData,
        saveAvatarImage
    } = rest;

    const { currentUser } = useAuth();

    const [editAvatar, setEditAvatar] = useState(false);
    const [imgSrc, setImgSrc] = useState(currentUser?.info?.user.photoURLOrigin ? 
        currentUser?.info?.user.photoURLOrigin + '?' + new Date().getTime() : null)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<any>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect] = useState<number | undefined>(1);

    // const originalImage = currentUser?.info?.user.photoURL + '?' + new Date().getTime();

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                );

                if (previewCanvasRef.current) {
                    previewCanvasRef.current.toBlob((blob) => {
                        setAvatarData({
                            image: blob, crop: completedCrop, scale, rotate
                        });
                    });
                }

            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    useEffect(() => {
        if (!imgSrc) {
            setEditAvatar(true);
        }
    }, [imgSrc]);
    

    // useEffect(() => {
    //     if (currentUser?.info?.user.avatarData) {
    //         const { crop, scale, rotate } = currentUser?.info?.user.avatarData;
            
    //         setCrop(crop);
    //         setCompletedCrop(crop);
    //         setScale(scale);
    //         setRotate(rotate);
    //     }
    // }, [currentUser?.info?.user.avatarData]);

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '');                
            });

            setOriginImage(e.target.files[0]);
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    async function handleSaveAvatar() {
        setEditAvatar(false);
        await saveAvatarImage();
    }

    // function onDownloadCropClick() {
    //     if (!previewCanvasRef.current) {
    //         throw new Error('Crop canvas does not exist')
    //     }

    //     previewCanvasRef.current.toBlob((blob) => {
    //         if (!blob) {
    //             throw new Error('Failed to create blob')
    //         }
    //         if (blobUrlRef.current) {
    //             URL.revokeObjectURL(blobUrlRef.current)
    //         }
    //         blobUrlRef.current = URL.createObjectURL(blob)
    //         hiddenAnchorRef.current!.href = blobUrlRef.current
    //         hiddenAnchorRef.current!.click()
    //     })
    // }

    return (
        <div>
            {!completedCrop &&
                <h4>{CS.pages.profile.avatar.title}</h4>
            }

            <div className="container">
                <div className="row">
                    {editAvatar && imgSrc &&
                        <div className="col-md-8">
                            <ReactCrop
                                crop={crop}
                                keepSelection={true}
                                disabled={!editAvatar}
                                minWidth={100}
                                maxWidth={100}
                                onChange={(_, percentCrop) => {
                                    setCrop(percentCrop);
                                }}
                                onComplete={(c) => {
                                    setCompletedCrop(c)
                                }}
                                aspect={aspect}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    crossOrigin="anonymous"
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </div>
                    }
                    {!!completedCrop && (
                        <div className="col-md-4">
                            <canvas
                                className={"avatar-preview" + (!editAvatar ? ' hidden' : '')}
                                ref={previewCanvasRef}
                            />
                        </div>
                    )}
                </div>
            </div>

            <br />

            {imgSrc && editAvatar &&
                <div className="action-panel">
                    <label className="form-label">{CS.pages.profile.avatar.zoom}: {scale}x</label>
                    <input
                        id="scale-input"
                        className="form-range"
                        type="range"
                        step="0.1"
                        min={.2} 
                        max={4}
                        value={scale}
                        disabled={!imgSrc}
                        onChange={(e) => setScale(Number(e.target.value))}
                    />
                    <br />
                    <label className="form-label">{CS.pages.profile.avatar.rotate}: {rotate}°</label>
                    <input
                        id="rotate-input"
                        className="form-range"
                        type="range"
                        step="1"
                        min={-90} 
                        max={90}
                        value={rotate}
                        disabled={!imgSrc}
                        onChange={(e) =>
                            setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                        }
                    />
                </div>
            }

            {!editAvatar &&
                <button type="button" className='btn btn-primary' onClick={() => setEditAvatar(true)}>
                    {CS.pages.profile.avatar.edit}
                </button>
            }

            {editAvatar &&
                <>
                    <br />
                    <button type="button" disabled={!completedCrop} className='btn btn-danger' onClick={() => setEditAvatar(false)}>
                        <MdOutlineCancel size={24} /> {CS.labels.cancel}
                    </button>&nbsp; 
                    <button type="button" disabled={!completedCrop} className='btn btn-primary' onClick={() => {
                        handleSaveAvatar();
                    }}>
                        <FiSave size={24} /> {CS.labels.save}
                    </button>&nbsp; 

                    <button type="button" className='btn btn-primary' onClick={() => {
                            const item = document.querySelector('#file-upload');
                            (item as any).click();
                        }}>
                        {CS.pages.profile.avatar.uploadImage}
                    </button>
                </>
            }

            <input
                accept="image/*"
                className='hidden-element'
                type="file"
                id="file-upload"
                onChange={onSelectFile}
            />

            {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}

            {/* <button type="button" className='btn btn-primary' onClick={handleSubmit}>
                Ziskat
            </button> */}

            <br />
            <hr />
        </div>
    );
}

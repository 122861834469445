import "./reportError.scss"
import CS from '@root/i18n/cs';
import React, { useState } from "react";
import { useAuth } from '@root/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import { reportError } from "@root/service/reports";

import { useMessageManager } from '@root/contexts/MessageManager';
import { showMessage } from '@root/actions/messageManager-actions';
import { Roles, ReportType } from '@root/helpers/constants';

export default function ReportError() {
    const [busy, setBusy] = useState(false);
    const [reportType, setReportType] = useState<ReportType>(ReportType.ERROR);

    const { currentUser, refreshUser } = useAuth();
    const [message, setMessage] = useState('');
    const { dispatchMessages } = useMessageManager();
    const navigate = useNavigate();

    async function handleSubmit(e: React.SyntheticEvent) {
        setBusy(true);
        e.preventDefault();
        // setBusy(true);
        try {
            await reportError(reportType, message);
            await refreshUser();
            setBusy(false);

            if (reportType === ReportType.ERROR) {
                dispatchMessages(showMessage({
                    type: 'info',
                    message: CS.pages.report.error.afterSend
                }));
            } else {
                dispatchMessages(showMessage({
                    type: 'info',
                    message: CS.pages.report.news.afterSend
                }));
            }
            navigate('/');
            
        } catch(error: any) {
            setBusy(false);
            dispatchMessages(showMessage({
                type: 'error',
                message: error.error || error.message
            }));
        }
    }

    return (
        <div className="container" id="report-error">
            {busy &&
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }

            {currentUser && currentUser.info && currentUser.info.user.role === Roles.ADMIN &&
                <div className="alert alert-success" role="alert">
                    <br />
                    <div className="form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio" value={ReportType.ERROR} name="owner"
                                checked={reportType === ReportType.ERROR}
                                onChange={() => setReportType(ReportType.ERROR)}
                            />
                            <b>{CS.pages.report.reportError} </b>
                        </label>
                    </div>
                    <br />
                    <div className="form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio" value={ReportType.NEWS} name="owner"
                                checked={reportType === ReportType.NEWS}
                                onChange={() => setReportType(ReportType.NEWS)}
                            />
                            <b>{CS.pages.report.reportNews} </b>
                        </label>
                    </div>
                </div>
            }


            {reportType === ReportType.ERROR && 
                <>
                    <h2>{CS.pages.report.error.header}</h2>
                    <br />
                    <div className="alert alert-primary" role="alert">
                        {CS.pages.report.error.instructions}
                    </div>
                </>
            }

            {reportType === ReportType.NEWS && 
                <>
                    <h2>{CS.pages.report.news.header}</h2>
                    <br />
                </>
            }

            <form onSubmit={handleSubmit}>
                {reportType === ReportType.ERROR && 
                    <div className="alert alert-primary" role="alert">
                        <div className="mb-3">
                            <label className="form-label">{CS.pages.report.error.questions.title}</label>
                        </div>

                        <ul className="list-group">
                            <li className="list-group-item list-group-item-info">{CS.pages.report.error.questions.q1}</li>
                            <li className="list-group-item list-group-item-info">{CS.pages.report.error.questions.q2}</li>
                            <li className="list-group-item list-group-item-info">{CS.pages.report.error.questions.q3}</li>
                        </ul>

                        <br />
                        <div className="mb-3">
                            {CS.pages.report.error.questions.final}
                        </div>
                    </div>
                }

                <textarea 
                    className="form-control" id="note"
                    value={message}
                    required={true}
                    onChange={e => setMessage(e.target.value!)}
                    maxLength={reportType === ReportType.NEWS ? 10000 : 2000}
                    rows={5}>
                </textarea>
                <br />

                <button type="submit" className='btn btn-primary'>
                    {CS.labels.sendReport}
                </button>

                <br /><br />
            </form>
        </div>
    );
}

import "./Footer.scss";

import { NavLink } from 'react-router-dom';

import CS from '@root/i18n/cs';
import { useAuth } from '../contexts/AuthContext';

export default function Footer() {

    const { currentUser } = useAuth();

    function handleLinkClick() {
        setTimeout(() => {
            window.scroll({ top: 0, behavior: 'smooth' });
        }, 1);
    }

    return (
        <div className="footer-wrapper">
            <footer
                className="text-center text-lg-start text-white"
                style={{ backgroundColor: "#1c2331"}}
            >
                <section
                    className="d-flex justify-content-between p-4"
                    style={{ backgroundColor: "#6351ce"}}
                >
                    {/* <div className="me-5">
                        <span>Get connected with us on social networks:</span>
                    </div> */}

                    {/* <div>
                        <a href="" className="text-white me-4">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="" className="text-white me-4">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="" className="text-white me-4">
                            <i className="fab fa-google"></i>
                        </a>
                        <a href="" className="text-white me-4">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="" className="text-white me-4">
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="" className="text-white me-4">
                            <i className="fab fa-github"></i>
                        </a>
                    </div> */}
                </section>

                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                                <h6 className="fw-bold">Kaminkovnik</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ backgroundColor: "#7c4dff", width: '140px', height: '2px'}}
                                />
                                <div>
                                    <NavLink onClick={handleLinkClick} className="nav-link" to="/about">{CS.components.mainMenu.about}</NavLink>
                                    {!currentUser && 
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/start#loginRegister">{CS.components.mainMenu.loginRegister}</NavLink>
                                    }
                                    {currentUser && currentUser.info &&
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/stones">{CS.components.mainMenu.stones}</NavLink>
                                    }

                                    {currentUser && currentUser.info &&
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/addstone">{CS.components.mainMenu.newStone}</NavLink>
                                    }
                                    {currentUser &&
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/profile">{CS.components.mainMenu.profile}</NavLink>
                                    }
                                    {currentUser &&
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/report">{CS.components.mainMenu.report}</NavLink>
                                    }
                                </div>
                            </div>

                            <div className="col-md-2 col-lg-3 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Informace</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ backgroundColor: "#7c4dff", width: '140px', height: '2px'}}

                                />
                                <NavLink to="/privacy" onClick={handleLinkClick} className="nav-link">Podmínky použití</NavLink>
                                <NavLink to="/gdpr" onClick={handleLinkClick} className="nav-link">GDPR</NavLink>
                            </div>

                            {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Právní a ochrana soukromí</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ backgroundColor: "#7c4dff", width: '140px', height: '2px'}}

                                />
                                <p>
                                    <a href="#!" className="text-white">Your Account</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-white">Become an Affiliate</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-white">Shipping Rates</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-white">Help</a>
                                </p>
                            </div> */}

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold">Kontakt</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ backgroundColor: "#7c4dff", width: '140px', height: '2px'}}

                                />
                                {/* <p><i className="fas fa-home mr-3"></i> New York, NY 10012, US</p> */}
                                <p><i className="fas fa-envelope mr-3"></i> info@kaminkovnik.cz</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div
                    className="text-center p-3"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.2)"}}

                >
                    © 2024 Copyright <a className="text-white" href="https://kaminkovnik.cz/">kaminkovnik.cz</a>
                </div>
            </footer>
        </div>
    )
}
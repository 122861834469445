import { useState, useEffect } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';

import { ZipItem, ImageData } from "@root/service/stoneService";

import ZipSelector from '../../components/ZipSelector';
import CS from '@root/i18n/cs';

export interface ImageItemProps {
    index: number;
    item: ImageData;
    zipChanged: any;
    initialSelectedZIPList?: ZipItem[];
    selectedZipList: ZipItem[];
    remove?: (image?: any) => void;
}


export default function ImageItem({ ...rest }: ImageItemProps) {
    const {
        index,
        item,
        zipChanged,
        selectedZipList,
        initialSelectedZIPList,
        remove
    } = rest;

    const [oldSelectedZipList, setOldSelectedZipList] = useState<ZipItem[]>([]);
    useEffect(() => {
    	// reduce initial zip list to contains only previously storred zip items
    	setOldSelectedZipList(selectedZipList.filter((item: ZipItem) => {
    		return initialSelectedZIPList?.find(
    			itm => itm.zip === item.zip && 
    			itm.name === item.name &&
    			itm.district === item.district
    		);
    	}));
    }, [])

    let src = item.image ? URL.createObjectURL(item.image) : item.url;
    return (
        <div className="card p-1">
            <h1>{CS.components.imageItem.title} {index + 1}</h1>
            <img src={src} className="card-img-top" alt="img" />

            <div className="card-body">
                {/* {moment(item.lastModified).format('MMMM Do YYYY, hh:mm')} */}

                <div className="alert alert-primary" role="alert">
                    {CS.components.imageItem.zipInfo}
                </div>
                <ZipSelector
                    items={selectedZipList}
                    displayAll={true}
                    ignoreFilter={true}
                    changed={zipChanged}
                    initialSelectedZIPList={oldSelectedZipList}
                />

                <br />
                {remove &&
                    <div className="d-flex flex-row-reverse">
                        <button className='btn btn-danger' onClick={() => remove()} >
                            <BsFillTrashFill /> {CS.components.imageItem.remove}
                        </button>
                    </div>
                }
            </div>



        </div>
    );
};
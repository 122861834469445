import * as types from './action-types';
import { StoneState } from '@root/helpers/constants';
export interface SearchOptions {
    zip?: string;
    userName?: string;
    state?: StoneState
}

export interface Message {
    type: string;
    message: string;
}

export const searchStone = (search: SearchOptions) => {
    return {
        type: types.SEARCH,
        value: search,
    };
};
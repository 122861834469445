import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import "./PasswordRules.scss";
import CS from '@root/i18n/cs';

interface PasswordRulesProps {
    password: string,
    passwordValidate: string,
    passwordMatch: boolean;
    setPasswordMatch: Dispatch<SetStateAction<boolean>>,
    setPasswordisValid: Dispatch<SetStateAction<boolean>>,
}

export default function PasswordRules({ ...rest }: PasswordRulesProps) {
    const { 
        password, 
        passwordValidate, 
        setPasswordisValid,
        passwordMatch,
        setPasswordMatch
    } = rest;

    const [passwordValidators, setPasswordValidators] = useState<any>(undefined);

    useEffect(() => {
        if (passwordValidators) {
            const { containsNumber, containsUpperCase, containsLowerCase } = passwordValidators;
            setPasswordisValid(containsNumber && containsUpperCase && containsLowerCase);
        }
    }, [passwordValidators]);

    useEffect(() => {
        let containsNumber = false;
        let containsUpperCase = false;
        let containsLowerCase = false;

        if (/[0-9]/.test(password)) {
            containsNumber = true;
        }

        if (/[A-Z]/.test(password)) {
            containsUpperCase = true;
        }

        if (/[a-z]/.test(password)) {
            containsLowerCase = true;
        }

        setPasswordValidators({ containsNumber, containsUpperCase, containsLowerCase });
    }, [password]);
    
    useEffect(() => {
        setPasswordMatch(password === passwordValidate);
    }, [password, passwordValidate]);

    const isValid = !(
        !passwordValidators || 
        !passwordValidators.containsNumber || 
        !passwordValidators.containsUpperCase || 
        !passwordValidators.containsLowerCase
    );

    return (
        <div className="password-rules">
            {!isValid &&
                <div className="alert alert-warning" role="alert">
                    <ul>
                        {password.length < 8 && <li>{CS.components.passwordRules.minLength}</li>}
                        {!passwordValidators || !passwordValidators.containsNumber && <li>{CS.components.passwordRules.requiredNumber}</li>}
                        {!passwordValidators || !passwordValidators.containsUpperCase && <li>{CS.components.passwordRules.requiredUppercaseLetter}</li>}
                        {!passwordValidators || !passwordValidators.containsLowerCase && <li>{CS.components.passwordRules.requiredLowercaseLetter}</li>}
                    </ul>
                </div>
            }

            {passwordValidators !== undefined && isValid && !passwordMatch && 
                <div className="alert alert-warning" role="alert">
                    <ul>
                        <li>{CS.components.passwordRules.samePassword}</li>
                    </ul>
                </div>
            }
        </div>
    )
}
import "./StoneGallery.scss"
import { Dispatch, SetStateAction } from 'react';
import Gallery from "react-photo-gallery";

import { StonePointer } from "@root/service/stoneService";

export interface StoneImageContainerProps {
    photos: any;
    anotate?: boolean;
    stonePointersHook?: [any, Dispatch<SetStateAction<any>>];
    onClick: (e: any, data: any) => void;
}

export default function StonePreviewGallery({ ...rest }: StoneImageContainerProps) {
    const { 
        photos, 
        onClick
    } = rest;

    const renderImage = ({ ...rest }: any) => {
        const { index, photo } = rest;
        const { stoneId, ...photoParams } = photo;
            
        return (
            <span key={index} className="image-wrapper" onClick={(e) => { onClick(e, { index }); }}>
                {photo.pointers && photo.pointers.length > 0 &&
                    <ul className="image-pointer-avatar-wrapper">
                        {photo.pointers.filter((pointer: StonePointer) => pointer.user).map((pointer: StonePointer) => {
                            return (
                                <li className="image-pointer-avatar" title={pointer.user?.userName}>
                                    <img src={pointer.user?.photoURL} alt="Avatar"></img>
                                </li>
                            );
                        })}
                    </ul>
                }
                <img
                    style={{ 'margin': '2px', 'display': 'block', 'cursor': 'pointer' }}
                    alt={photo.title}
                    {...photoParams}
                />

            </span>
        );
    }

    return (
        <Gallery 
            renderImage={renderImage} 
            photos={photos}            
        />
    );
};

import "./ScrollTop.scss";

import { useEffect, useState } from 'react';
import { BsArrowUpSquare } from 'react-icons/bs';

export default function Footer() {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () =>  setShowButton(window.scrollY > 300);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    function handleScrollUp() {
        window.scroll({ top: 0, behavior: 'smooth' })
    }

    return (
        <>
            <div className={"scroll-top " + (showButton ? "visible" : "")}>
                <BsArrowUpSquare size={48} color="gray" onClick={handleScrollUp}/>
            </div>
        </>
    )
}
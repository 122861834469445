import { isTestEnv } from '@root/helpers/shared';
let API_path;

if (document.location.hostname === "localhost" || document.location.host.startsWith('192.168')) {
    let stage = 'test';
    // stage = 'prod';
    // const localPath = '192.168.1.191';
    const localPath = 'localhost';
    API_path = `http://${localPath}:4000/${stage}`;
    // API_path = 'https://test-api.kaminkovnik.cz/v1';
} else {
    API_path = isTestEnv() ?
        'https://test-api.kaminkovnik.cz/v1' : 'https://zfpjwic6v0.execute-api.eu-central-1.amazonaws.com/prod';
        // 'https://test-api.kaminkovnik.cz/v1' : 'https://prod-api.kaminkovnik.cz/v1';
}


export const USER = {
    test: `${API_path}/user`,
    createUser: `${API_path}/user`,
    removeUser: `${API_path}/remove-user`,
    userExists: `${API_path}/user`
}

export const TRACKING = {
    getStones: `${API_path}/trackings`,
    getInspectStones: `${API_path}/inspect-trackings`,
    getStone: `${API_path}/tracking`,
    getUploadFile: `${API_path}/getUploadFile`,
    createTracking: `${API_path}/tracking`,
    deleteTracking: `${API_path}/tracking`,
    updateTracking: `${API_path}/tracking`,
    // likes
    updateLikes: `${API_path}/update-likes`,
    updateStonePointer: `${API_path}/stone`,
    approveInspectStones: `${API_path}/update-inspect-trackings`,
    getLatestStones: `${API_path}/trackings`,
    LatestStones: `${API_path}/trackings`,
    getLatestStonesPublic: `${API_path}/publicTrackings`,
    // comments
    removeComment: `${API_path}/comments`,
    updateComment: `${API_path}/comments`,
    // messages
    visitMessage: `${API_path}/visitMessage`,
    visitMessagePanel: `${API_path}/visitMessagePanel`,
    hideMessage: `${API_path}/hideMessage`,
}

export const MAP = {
    getCity: `${API_path}/getCity`
}

export const REPORTS = {
    reportError: `${API_path}/reportError`
}
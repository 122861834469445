
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// @ts-ignore
import * as ReactDOMClient from 'react-dom/client';
import App from './App';

import {
    HashRouter
} from "react-router-dom";


window.onerror = function (msg: any, url, lineNo, columnNo, error) {
    var string = msg.toLowerCase();
    var substring = 'script error';
    if (string.indexOf(substring) > -1){
      console.log('Script Error: See Browser Console for Detail');
    } else {
        var message = [
            'Message: ' + msg,
            'URL: ' + url,
            'Line: ' + lineNo,
            'Column: ' + columnNo,
            'Error object: ' + JSON.stringify(error)
        ].join(' - ');

        console.error(message);
    }

    return false;
};

const rootElement: any  = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);
root.render(
    <HashRouter>
        <App />
    </HashRouter>
);
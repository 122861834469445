import "./MainMenu.scss";

import { useState } from 'react';
import { NavLink } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import CS from '@root/i18n/cs';

import { useAuth } from '../contexts/AuthContext';
import { Affix } from 'rsuite';
import { IoMdNotifications } from 'react-icons/io';
import { isTestEnv } from '@root/helpers/shared';
import { MessagePanel } from '@root/components';

import { Roles } from '@root/helpers/constants';
const isTest = isTestEnv();


export default function MainMenu() {
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();
    const [showMessagePanel, setShowMessagePanel] = useState(false);

    const reviewStones = currentUser?.info?.pendingStones || 0;
    const inspectStones = currentUser?.info?.pendingDataInpsectionStones || 0;
    const messageCount = currentUser?.info?.user?.messages ? currentUser.info.user.messages.filter((msg: any) => !msg.visit).length : 0;

    function handleLinkClick() {
        const el = document.querySelector('.navbar-toggler');
        if (el) {
            (el as any).click();
        }
    }

    async function handleLogoutClick(e: any) {
        e.preventDefault();
        
        await logout();
        navigate('/');
        handleLinkClick();
    }

    let menuTitle = '';
    if (currentUser?.info?.user) {
        menuTitle = 
            currentUser?.info?.user.role === 'admin' ? 
                `${currentUser?.info?.user.userName} ♪ ` : 
                (currentUser?.info?.user.userName ? ` ${currentUser?.info?.user.userName || ''}` : '');
    } else {
        menuTitle = CS.components.mainMenu.header;
    }

    return (
        <>  
            <Affix>
                <nav className={"navbar navbar-expand-lg navbar-light bg-light" + (isTest ? ' testEnv' : '')}>
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            {currentUser?.info?.user?.photoURL &&
                                <img className="navbar-brand-avatar" src={`${currentUser?.info?.user.photoURL}?${+new Date()}`} alt="Avatar"></img>
                            }
                            {menuTitle}
                        </a>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink onClick={handleLinkClick} className="nav-link" to="/about">{CS.components.mainMenu.about}</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink onClick={handleLinkClick} className="nav-link" to="/articles">{CS.components.mainMenu.articles}</NavLink>
                                </li>

                                {currentUser && currentUser.info &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/">
                                            {CS.components.mainMenu.home} &nbsp;
                                            {currentUser.info.user.newMessages > 0 &&
                                                <span className="badge bg-success rounded-pill">{currentUser.info.user.newMessages}</span>
                                            }
                                        </NavLink>
                                    </li>
                                }
                                {!currentUser && 
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/start#loginRegister">{CS.components.mainMenu.loginRegister}</NavLink>
                                    </li>
                                }
                                {currentUser && currentUser.info &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/stones">{CS.components.mainMenu.stones}</NavLink>
                                    </li>
                                }
                                {currentUser && currentUser.info && currentUser.info.user.role === Roles.ADMIN && reviewStones > 0 &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/approveStones">
                                            {CS.components.mainMenu.approveStones} <span className="badge bg-danger rounded-pill">{reviewStones}</span>
                                        </NavLink>
                                    </li>
                                }
                                {currentUser && currentUser.info && currentUser.info.user.role === Roles.ADMIN && inspectStones > 0 &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/inspectStones">
                                            {CS.components.mainMenu.inspectStones} <span className="badge bg-danger rounded-pill">{inspectStones}</span>
                                        </NavLink>
                                    </li>
                                }
                                {currentUser && currentUser.info &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/addstone">{CS.components.mainMenu.newStone}</NavLink>
                                    </li>
                                }
                                {currentUser &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/profile">{CS.components.mainMenu.profile}</NavLink>
                                    </li>
                                }
                                {currentUser &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLinkClick} className="nav-link" to="/report">{CS.components.mainMenu.report}</NavLink>
                                    </li>
                                }
                                {currentUser &&
                                    <li className="nav-item">
                                        <NavLink onClick={handleLogoutClick} className="nav-link" to="/logout">{CS.components.mainMenu.logout}</NavLink>
                                    </li>
                                }
                            </ul>

                            {/* {currentUser && currentUser.info && currentUser.info.user.messages &&
                                <li className="d-flex message-button">
                                    <div onClick={() => setShowMessagePanel(!showMessagePanel)}>
                                        <IoMdNotifications size={45} color="black" />&nbsp;
                                        <span className="badge bg-danger rounded-pill">{currentUser.info.user.messages.length}</span>
                                    </div>
                                </li>
                            } */}
                            {/* {currentUser?.info &&
                                <form className="d-flex" onSubmit={e => {
                                    e.preventDefault();

                                        dispatch(searchStone({ zip: search }));
                                }}>
                                    <input className="form-control search" type="search" 
                                        value={search} onChange={e => setSearch(e.target.value)} placeholder={CS.components.mainMenu.searchHelper} aria-label="Search" />
                                    <button className="btn" type="submit">{CS.components.mainMenu.search}</button>
                                </form>
                            } */}
                        </div>

                        {currentUser && currentUser.info && currentUser.info.user.messages &&
                            <div className="d-flex message-button">
                                <div onClick={() => setShowMessagePanel(!showMessagePanel)}>
                                    <IoMdNotifications size={40} color="black" />&nbsp;
                                    {messageCount > 0 &&
                                        <span className="badge bg-danger rounded-pill">
                                            {messageCount}
                                        </span>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </nav>
            </Affix>

            {showMessagePanel && currentUser?.info.user.messages &&
                <MessagePanel 
                    setShowMessagePanel={setShowMessagePanel}
                    messageCount={messageCount}
                ></MessagePanel>
            }
        </>
    )
}
import { getJwtToken } from '../contexts/AuthContext';

import { REPORTS } from '@root/config/endPoints';
import { ReportType } from '@root/helpers/constants';

export async function reportError(reportType: ReportType, message: string) {
    const { token } = await getJwtToken();

    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        },
        body: JSON.stringify({
            message, reportType
        })
    };

    return fetch(REPORTS.reportError, options)
        .then(resp => {
            if (!resp.ok) {
                throw resp;
            }
            return resp.json();
        })
        .then((resp: any) => {
            return resp.comments;
        })
        .catch(err => {
            return err.text().then((message: string) => {
                console.error('cannot create error report', err);
                throw JSON.parse(message);
            })
        });
}

import { useState, Dispatch, SetStateAction } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import "./PasswordToggle.scss";

interface PasswordToggleProps {
    password: string,
    setPassword: Dispatch<SetStateAction<string>>,
    readOnly?: boolean;
}

export default function PasswordToggle({ ...rest }: PasswordToggleProps) {
    const { 
        password, 
        setPassword,
        readOnly 
    } = rest;
    const [passwordIndex, setPasswordIndex] = useState(1);


    return (
        <div className="password-toggle">
            <input autoCapitalize="none" readOnly={readOnly || false} className="form-control" value={password} onChange={e => setPassword(e.target.value!)} type={
                ['text', 'password'][passwordIndex % 2]} />
            
            {passwordIndex % 2 === 0 &&
                <AiFillEye size={24} onClick={() => {
                    setPasswordIndex(passwordIndex + 1);
                }} />
            }
            {passwordIndex % 2 === 1 &&
                <AiFillEyeInvisible size={24} onClick={() => {
                    setPasswordIndex(passwordIndex + 1);
                }} />
            }
        </div>
    )
}
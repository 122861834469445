
export default function Footer() {
    return (
        <div className="container">
            <br />
            <br />
            <div className="row" id="loginRegister">
                <div className="col-md-6 offset-md-3 col-lg-12 offset-lg-0">
                    <h4>
                        Ochrana vašich osobních údajů (GDPR)
                    </h4>
                    <br />
                    <div>
                        Při vytváření účtu u nás budete požádáni o zadání vašeho uživatelského jména a hesla.
                        Tyto informace jsou nezbytné pro vytvoření a správu vašeho účtu. Kromě toho můžeme požádat o vaše poštovní směrovací
                        číslo a můžete nám poskytnout fotografie, které budou součástí vašeho profilu nebo veřejných příspěvků.
                        Zde je, jak s těmito údaji naložíme a jak je chráníme:
                    </div>
                    <br />

                    <div>
                        <h6>Účel zpracování:</h6>
                        <ul>
                            <li>Vaše uživatelské jméno a heslo jsou používány pro přístup k vašemu účtu.</li>
                            <li>Poštovní směrovací číslo pomáhá vytvářet lokalizovaný zážitek z naší služby.</li>
                            <li>Fotografie, které nahráváte, mohou být použity k personalizaci vašeho profilu a k sdílení v rámci naší komunity, podle vašeho výběru mezi soukromým a veřejným nastavením.</li>
                            <li>Vaše osobní údaje, včetně fotografií, místa nálezu a popisků, jsou zpracovávány za účelem umožnění vytváření veřejných nebo soukromých příspěvků na naší platformě. Tato data pomáhají vytvářet bohatší uživatelský zážitek tím, že umožňují sdílení s komunitou a podporují interakci mezi uživateli prostřednictvím hodnocení a komentářů</li>
                        </ul>
                    </div>

                    <div>
                        <h6>Sdílení údajů:</h6>
                        <ul>
                            <li>Vaše osobní údaje nebudou bez vašeho souhlasu sdíleny s třetími stranami, kromě případů vyžadovaných zákonem.</li>
                            <li>Můžeme využívat externí služby pro zpracování některých údajů, ale zajistíme, aby tyto strany dodržovaly stejné standardy ochrany údajů.</li>
                            <li>Osobní údaje mohou být sdíleny s ostatními uživateli platformy (pouze veřejné). Sdílení údajů s třetími stranami se provádí pouze s vaším výslovným souhlasem nebo pokud je to vyžadováno zákonem. Všechny třetí strany, se kterými sdílíme údaje, jsou pečlivě vybírány a musí dodržovat odpovídající ochranu dat.</li>
                        </ul>
                    </div>

                    <div>
                        <h6>Bezpečnost:</h6>
                        <ul>
                            <li>K ochraně vašich osobních údajů používáme špičkové bezpečnostní opatření, včetně služeb AWS Cloud, které jsou známé svou vysokou úrovní zabezpečení a spolehlivosti.</li>
                            <li>Vaše heslo je šifrováno a v našem systému nelze zobrazit. Dodržujeme nejlepší postupy a standardy průmyslu pro zabezpečení vašich informací a pravidelně aktualizujeme naše bezpečnostní protokoly.</li>
                            <li>Zavázali jsme se k ochraně vašich osobních údajů pomocí špičkových bezpečnostních opatření. Tyto zahrnují technická a organizační opatření, jako je šifrování dat a omezený přístup k údajům. Vaše data jsou chráněna před neoprávněným přístupem, ztrátou a zneužitím.</li>
                        </ul>
                    </div>
                    <div>
                        <h6>Vaše práva:</h6>
                        <ul>
                            <li><b>Editace profilu</b>: Máte možnost kdykoliv editovat svůj profil přímo v uživatelském rozhraní. To zahrnuje možnost změnit vaši profilovou fotografii, uživatelské jméno a poštovní směrovací číslo.</li>
                            <li><b>Odstranění účtu</b>: Pokud si přejete, můžete svůj účet kdykoliv odstranit. Po odstranění účtu budou z naší databáze trvale odstraněny všechny osobní údaje spojené s vaším profilem, včetně vašeho jména a profilové fotografie. Vaše příspěvky a s nimi související obrázky mohou být na platformě zachovány anonymně pro účely archivace, sdílení s komunitou a historického záznamu, bez jakékoli možnosti vaší osobní identifikace. Toto opatření je v souladu s našimi zásadami ochrany osobních údajů a platnými právními předpisy.</li>
                            <li><b>Editace a správa příspěvků</b>: Máte plnou kontrolu nad vašimi příspěvky, včetně možnosti je kdykoliv editovat nebo odstranit. To zahrnuje vaše fotografie, místo nálezu a popisky.</li>
                            <li><b>Další práva</b>: Kromě výše uvedeného máte právo získat přístup k vašim osobním údajům, požádat o jejich opravu, výmaz nebo omezení zpracování. Máte také právo na přenositelnost údajů.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>

    )
}
import React, { useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { reduceImageSize } from '@root/helpers/shared';

import { 
    CognitoUser, 
    AuthenticationDetails, 
    CognitoRefreshToken,
    // CognitoUserAttribute,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { uploadStoneImage } from "@root/service/stoneService";

const AUTH_USER_TOKEN_KEY = 'kaminkovnik-token-key';
const AUTH_USER_REFRESHTOKEN_KEY = 'kaminkovnik-refreshtoken-key';

export enum REGISTER_ERROR_CODES {
    UsernameExistsException = 'UsernameExistsException',
    UserNotConfirmedException = 'UserNotConfirmedException',
    UserNotFoundException = 'UserNotFoundException',
    NotAuthorizedException = 'NotAuthorizedException'
}

import { USER } from '@root/config/endPoints';
import { POOL_DATA } from './cognitoConfig';

interface Auth {
    getJwtToken: () => any;
    currentUser: any;
    refreshUser: any;
    login: (email: string, password: string) => Promise<any>;
    register: (userName: string, email: string, password: string) => Promise<any>;
    logout: any;
    forgotPassword: (email: string) => Promise<any>;
    confirmPassword: (email: string,  verificationCode: string, newPassword: string) => Promise<boolean>;
}

const AuthContext = React.createContext({} as Auth);

export function useAuth() {
    return useContext(AuthContext)
}

// 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token'
export async function getUserInfo(jwtToken: string | null) {
    if (!jwtToken) {
        return;
    }
    const options: any = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken,
        }
    };

    const url = USER.test;

    return fetch(url, options)
        .then(resp => {
            if (resp.status === 404) {
                return { error: true };
            }
            return resp.json();
        })
        .catch(err => {
            return err.text().then((message: string) => {
                console.error('cannot get user info', err);
                throw JSON.parse(message);
            })
        });
}

export interface AvatarData {
    image: Blob;
    crop: any
    scale: number;
    rotate: number
}

export async function saveAvatarImage(originImage?: Blob, avatarData?: AvatarData) {
    const { decodedJwt } = await getJwtToken();

    try {
        if (originImage) {
            const reducedImage = await reduceImageSize(originImage);
            await uploadStoneImage(reducedImage.outImage, 'origin' + decodedJwt.sub, true);
        }
        if (avatarData) {
            const { image } = avatarData;
            const reducedImage = await reduceImageSize(image);
            await uploadStoneImage(reducedImage.outImage, decodedJwt.sub, true);
        }
        return Promise.resolve();
    } catch(e: any) {
        return Promise.reject(e);
    }
}

export async function createOrUpdateUser(userName: string, zip: string, originImage?: Blob, avatarImage?: Blob) {
    const { token, decodedJwt } = await getJwtToken();

    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        },
        body: JSON.stringify({
            userName, zip
        })
    };

    if (originImage) {
        await uploadStoneImage(originImage, 'origin' + decodedJwt.sub, true);
    }
    if (avatarImage) {
        await uploadStoneImage(avatarImage, decodedJwt.sub, true);
    }

    return fetch(USER.createUser, options)
        .then(resp => {
            if (resp.status === 404) {
                throw resp;
            }
            return resp.json();
        })
}


export async function removeUser() {
    const { token } = await getJwtToken();

    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        },
    };

    return fetch(USER.removeUser, options)
        .then(resp => {
            return resp.json();
        })
        .catch(err => {
            return err.text().then((message: string) => {
                console.error('cannot delete user', err);
                throw JSON.parse(message);
            })
        });
}


export async function isExistingUser(userName: string) {
    const { token } = await getJwtToken();

    const options: any = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        }
    };

    return fetch(`${USER.userExists}/${userName}`, options)
        .then(resp => {
            if (!resp.ok) {
                throw resp;
            }
            return resp.json();
        })
        .catch(err => {
            return err.text().then((message: string) => {
                console.error('cannot check user', err);
                throw JSON.parse(message);
            })            
        });
}

export async function ensureUserExists() {
    throw Error('not implemented yet')

    // const db = getFirestore(app);
    // const userCol = collection(db, 'users');

    // const q = query(userCol, where("uid", "==", user.uid));
    // const stonesList: any = await getDocs(q)
    
    // return new Promise((resolve, reject) => {
    //     const resp = stonesList.docs
    //         .map((doc: any) => {
    //             let data = doc.data();
    //         });

    //     if (!resp.length) {
    //         addDoc(userCol, {
    //             name: user.displayName,
    //             email: user.email,
    //             uid: user.uid
    //         }).then(resp => {
    //             resolve(resp);
    //         })
    //         .catch(() => {
    //             reject("Cannot create sotone list");
    //         });
    //     }
    // });

}


export async function getJwtToken() {
    const token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    if (!token) {
        return Promise.resolve({ token: null, decodedJwt: null });
    }
    try {
        const decodedJwt: any = jwtDecode(token);
        if (decodedJwt.exp >= Date.now() / 1000) {
            // return { token, decodedJwt };
            return Promise.resolve({ token, decodedJwt });
        } else {
            const refreshTokenString = localStorage.getItem(AUTH_USER_REFRESHTOKEN_KEY);
            if (refreshTokenString) {
                localStorage.removeItem(AUTH_USER_REFRESHTOKEN_KEY);

                return new Promise<any>(async (resolve, reject) => {
                    const cognitoUser = new CognitoUser({
                        Username: decodedJwt.email,
                        Pool: new CognitoUserPool(POOL_DATA)
                    });
                    const refreshToken = new CognitoRefreshToken({ RefreshToken: refreshTokenString })
                    // @ts-ignore
                    cognitoUser.refreshSession(refreshToken, (err, session) => {
                        try {
                            const token = session.getIdToken().getJwtToken();
                            const payload = session.getIdToken().payload;
                            
                            localStorage.setItem(AUTH_USER_TOKEN_KEY, token);
                            localStorage.setItem(AUTH_USER_REFRESHTOKEN_KEY, session.getRefreshToken().getToken());
                            resolve({ token, decodedJwt: payload });
                        } catch(e) {
                            reject({ token: null, decodedJwt: null });                 
                        }
                    });
                });
            }
        }
    } catch (e) {}
    
    return Promise.resolve({ token: null, decodedJwt: null });
}

export function AuthProvider({ children }: { children: any }) {
    const [currentUser, setCurrentUser] = useState<any>();
    const [loading, setLoading] = useState(true);

    async function login(email: string, password: string) {
        setLoading(true);

        const userPool = new CognitoUserPool(POOL_DATA);
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session) => {
                    
                    const token = session.getIdToken().getJwtToken();
                    const payload = session.getIdToken().payload;
                    
                    localStorage.setItem(AUTH_USER_TOKEN_KEY, token);
                    localStorage.setItem(AUTH_USER_REFRESHTOKEN_KEY, session.getRefreshToken().getToken());

                    const userInfo = getUserData(token)
                    userInfo.then(resp => {
                        setLoading(false);
                        if (!resp.error) {
                            const finalResp = { ...payload, info: resp, uid: payload.sub };
                            setCurrentUser(finalResp);
                            resolve(finalResp);
                        } else {
                            setCurrentUser({ ...payload, uid: payload.sub });
                            // setCurrentUser({ ...resp });
                            reject(resp);
                        }
                    }).catch(() => {
                        setCurrentUser(null);
                        setLoading(false);
                    });
                },
                onFailure: (err) => {
                    setLoading(false);
                    reject(err);
                }
            });
        });
    }

    async function register(userName: string, email: string, password: string) {        
        const cognitoUser = new CognitoUserPool(POOL_DATA);

        console.log(userName);

        return new Promise((resolve, reject) => {

            const userAttributes: any = [];
            // [
            //     new CognitoUserAttribute({
            //         Name: 'userName',
            //         Value: userName
            //     }),
            // ];

            cognitoUser.signUp(email, password, userAttributes, [], (err: any, result) => {
                if (err) {
                    reject(err);
                } else {
                    console.log(result);
                    resolve(result);
                }
            });
        });
    }
    
    async function forgotPassword(email: string) {    
        const cognitoUserPool = new CognitoUserPool(POOL_DATA);
        const cognitoUser = new CognitoUser({ Pool: cognitoUserPool, Username: email });

        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({ 
                onSuccess: function(result) {
                    resolve(result);
                },
                onFailure: function(err) {
                    reject(err);
                },
                // inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
                //     var verificationCode: any = prompt('Zadejte verifikacni kod ', '');
                //     var newPassword: any = prompt('Zadejte nove heslo ', '');
                //     cognitoUser.confirmPassword(verificationCode, newPassword, this);
                // }
            });
        });
    }

    async function confirmPassword(email: string,  verificationCode: string, newPassword: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const cognitoUserPool = new CognitoUserPool(POOL_DATA);
            const cognitoUser = new CognitoUser({ Pool: cognitoUserPool, Username: email });
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: () => {
                    resolve(true);
                }, 
                onFailure: (err) => {
                    reject(err);
                }
            });
        });
    }

    
    function logout() {
        delete localStorage[AUTH_USER_TOKEN_KEY];
        setCurrentUser(null);
        return Promise.resolve();
    }

    async function refreshUser() {
        const { token, decodedJwt } = await getJwtToken();

        const userInfo = getUserData(token!)
        userInfo.then(resp => {
            setCurrentUser({ ...decodedJwt, info: resp, uid: decodedJwt.sub });
            // setLoading(false);
        }).catch(() => {
            // setLoading(false);
        });
    }

    async function getUserData(jwtToken: string) {
        return getUserInfo(jwtToken);
    }

    // async function toDataURL(url: string) {
    //     return new Promise((resolve, reject) => {
    //         const img = new Image();
    //         img.crossOrigin = 'Anonymous';
    //         img.onload = () => {
    //             let canvas: any = document.createElement('CANVAS')
    //             const ctx = canvas.getContext('2d')
    //             canvas.height = img.height;
    //             canvas.width = img.width;
    //             ctx.drawImage(img, 0, 0);
    //             const dataURL = canvas.toDataURL();
    //             canvas = null;
    //             resolve(dataURL)
    //         }
    //         img.src = url;
    //     })
    // }

    // async function loginWithFacebook() {
    //     const provider = new FacebookAuthProvider();
    //     provider.addScope('user_birthday');
        
    //     return signInWithPopup(auth, provider)
    //         .then((result) => {
    //             const credential = FacebookAuthProvider.credentialFromResult(result);
                
    //             debugger
    //             if (credential) {
    //                 const accessToken = credential.accessToken;
    //                 const photoURL = result.user.photoURL + "?height=500&access_token=" + accessToken;
    //                 updateProfile(result.user, { photoURL }); 
    //                 // debugger;
    //                 // const url = auth.currentUser?.photoURL;
    //                 // (result.user as any).accessToken = accessToken;
    //                 // (result.user as any).chleba = 1;
    //             }
    //             return result;
    //         })
    //         .catch(e => {
    //             debugger;
    //         })
    // }

    // async function loginWithGoogle() {
    //     const provider = new GoogleAuthProvider();
    //     provider.addScope('profile');
    //     provider.addScope('email');

    //     return signInWithPopup(auth, provider)
    //         .then(async (result) => {
    //             const credential = GoogleAuthProvider.credentialFromResult(result);
    //             // const credential = FacebookAuthProvider.credentialFromResult(result);
    //             if (credential) {
    //                 const accessToken = credential.accessToken;
    //                 const photoURL = result.user.photoURL + "?height=500&access_token=" + accessToken;
    //                 debugger;
    //                 updateProfile(result.user, { photoURL }); 

    //                 const userInfo = await getUserData(result.user);
    //                 if (userInfo && userInfo.info) {
    //                     const base64 = await toDataURL(photoURL);
    //                     await updateUser(result.user.uid, item => {
    //                         return { photoUrl: base64 }
    //                     })
    //                 }
    //                 // const url = auth.currentUser?.photoURL;
    //                 // (result.user as any).accessToken = accessToken;
    //                 // (result.user as any).chleba = 1;
    //             }
    //             return result;
    //         });
    // }

    // function updateUserProfile(userName: string) {
    //     return updateProfile(currentUser, { displayName: userName });
    // }

    // async function getUserData(user: any) {
    //     const info = await getUserInfo(user.uid);
    //     let resp = { ...user, info };
    //     if (info) {
    //         const userStones = await getUserStones(user);

    //         // const photoUrl = user.photoURL + "?height=500&access_token=" + user.accessToken;
    //         // updateProfile(user, { photoURL: photoUrl }); 

    //         if (resp.info.role === Roles.ADMIN) {
    //             const search = { state: StoneState.PENDING };
    //             const pendingStones = await getStones({ search });

    //             if (pendingStones.stones.length) {
    //                 resp = { ...user, info, userStones, pendingStones: pendingStones.stones };
    //             } else {
    //                 resp = { ...user, info, userStones };
    //             }
    //         } else {
    //             resp = { ...user, info, userStones };
    //         }
    //     }
    //     return resp;
    // }

    // async function refreshUser(user: any) {
    //     user = user || currentUser;
        
    //     if (user) {
    //         return new Promise(async (resolve, reject) => {
    //             try {
    //                 const userInfo = await getUserData(user);
    //                 setCurrentUser(userInfo);
    //                 resolve(userInfo);
    //                 setLoading(false);
    //             } catch(e) {
    //                 setCurrentUser(null);
    //                 setLoading(false);
    //                 reject();
    //             }

    //             // getUserInfo(user.uid).then(async info => {
    //             //     // zjisti pocet kaminku, ktere uzivatel ma
    //             //     if (info) {
    //             //         const userStones = await getUserStones(user);
    //             //         setCurrentUser({ ...user, info, userStones });
    //             //     } else {
    //             //         setCurrentUser(user);
    //             //     }
    //             //     setLoading(false);
    //             //     resolve(user);
    //             // })
    //             // .catch(() => {
    //             //     setCurrentUser(null);
    //             //     setLoading(false);
    //             //     reject();
    //             // })
    //         });
    //     } else {
    //         // opravit tohle svinstvo
    //         return Promise.resolve(user);
    //     }
    // }

    useEffect(() => {
        getJwtToken().then(resp => {
            const { token, decodedJwt } = resp;

            try {
                if (decodedJwt) {
                    const userInfo = getUserData(token!);
                    userInfo.then(resp => {
                        if (!resp.error) {
                            setCurrentUser({ ...decodedJwt, info: resp, uid: decodedJwt.sub });
                        } else {
                            setCurrentUser({ ...decodedJwt, uid: decodedJwt.sub });
                        }
                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    })
                } else {
                    setLoading(false);
                }
            } catch(e) {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    }, []);

    // useEffect(() => {
    //     return auth.onAuthStateChanged(async (user: any) => {
    //         // pokud existuje uzivatel nacti jeho informace
    //         if (user) {
    //             try {
    //                 const userInfo = await getUserData(user);
    //                 await updateUser(user.uid, item => {
    //                     return { photoURL: user.photoURL }
    //                 })
    //                 setCurrentUser(userInfo);
    //                 setLoading(false);
    //             } catch(e) {
    //                 setCurrentUser(null);
    //                 setLoading(false);
    //             }

    //             // getUserInfo(user.uid).then(async (info) => {
    //             //     // zjisti pocet kaminku, ktere uzivatel ma
    //             //     if (info) {
    //             //         const userStones = await getUserStones(user);
    //             //         setCurrentUser({ ...user, info, userStones });
    //             //     } else {
    //             //         setCurrentUser(user);
    //             //     }
    //             //     setLoading(false);
    //             // })
    //             // .catch(() => {
    //             //     setCurrentUser(null);
    //             //     setLoading(false);
    //             // })
    //         } else {
    //             setCurrentUser(user);
    //             setLoading(false);
    //         }
    //     });
    // }, []);



    const value = {
        getJwtToken,
        currentUser,
        refreshUser,
        login,
        register,
        forgotPassword,
        confirmPassword,
        // updateUserProfile,
        // loginWithFacebook,
        // loginWithGoogle,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
            {loading && 
                <div className="busy-indicator-wrapper">
                    <div className="busy-indicator">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>
            }
        </AuthContext.Provider>
    );
}